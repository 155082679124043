import React from 'react'
import { saveData } from '../../db/professionsSandbox'
import { deepEqual } from '../../components/objectFunctions'

export default class Autosave extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
      saveStatus: false,
      saveMessage: "Autosaved",
    }
    
    this.necessaryDataChanged = this.necessaryDataChanged.bind(this)
  }

  isProd(){
    return !window.location.href.includes('localhost')
  }

  showSavedMessage(str = "Autosaved") {
    this.setState({
      isSaving: false,
      saveMessage: str,
      saveStatus: true
    });

    setTimeout(() => {
      this.setState({ saveStatus: false });
    }, 2000);
  }

  save() {
    this.setState({ isSaving: true })

    if (this.isProd()){
      saveData({
        profession: this.props.profession, 
        parent_career: this.props.parent_career,
        name: this.props.name,
        raw: this.props.raw, 
        deleted: this.props.deleted, 
        stops: this.props.stops, 
        tabs: this.props.tabs,
        specialisations: this.props.specialisations,
        profession_synonyms: this.props.profession_synonyms
      }, (error, res) => {
        if (!error) {
          this.showSavedMessage()
        } else {
          this.showSavedMessage('error')
        }
      })
    }
  }

  necessaryDataChanged(prevProps) {
    const dataKeys = ['raw', 'deleted', 'stops', 'tabs', 'specialisations', 'profession_synonyms']

    for (let key of dataKeys) {
      const previous = prevProps[key]
      const current = this.props[key]
      
      if (!deepEqual(previous, current)) {
        return true
      }
    }
    
    return false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editReady === true && this.necessaryDataChanged(prevProps) && this.state.isSaving === false) {
      this.save()
    }
  }

  render() {
    const visible = this.state.saveStatus === false ? "" : "visible";
    const className = `saveMessage ${visible}`;

    return <div className={className}>{this.state.saveMessage}!</div>;
  }
}