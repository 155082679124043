import React from "react"
import { UserAuth } from '../context/AuthContext';
import Nav from './Nav'
import LogoutBtn from './LogoutBtn'
import logo from "../img/logo.svg"

function Header({user}) {
  const userImg = user?.photoURL
  const icon = userImg ? <></> : <i className="fi fi-rr-user"></i>

  return <header className="fixed">
    <div className="header__block">
      <button>
        <i className="fi fi-rr-envelope"></i>
      </button>
    </div>
    <div className="header__block">
      <button>
        <i className="fi fi-rr-bell"></i>
      </button>
    </div>
    <div className="header__block">
      <div className="userAvatar" style={{ backgroundImage: `url(${userImg})` }}>
        {icon}
      </div>
    </div>
  </header>
}

export default function Layout({path, children}) {
  const { user } = UserAuth();

  return (<>
    <Header user={user} />
    <main>
      <section className="mainSection">
        <div className="leftBar">
          <div className="leftBar__head">
            <img src={logo} alt="Logo" className="leftBar__logo" />
            <span className="leftBar__version">v{process.env.REACT_APP_VERSION}</span>
          </div>
          <div className="leftBar__flex">
            {!user.isAdmin && <p>You have no rights here</p>}
            
            <Nav path={path} />
            <div>
              <LogoutBtn />
            </div>
          </div>
        </div>
        <div className="mainZone" style={{ marginTop: '72px'}}>
          {children}
        </div>
      </section>
    </main>
  </>)
}