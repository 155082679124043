import React from "react";
import { useNavigate } from 'react-router-dom';

export default function Nav({path}){
  const navigate = useNavigate()
  return <nav>
    <ul>
      <li className={path === 'professions' ? 'current' : ''} onClick={() => { navigate('/professions') }}>
        <i className="fi fi-rr-comment-user"></i>
        Professions
      </li>
      <li className={path === 'courses' ? 'current' : ''} onClick={() => { navigate('/courses') }}>
        <i className="fi fi-rr-e-learning"></i>
        Courses
      </li>
    </ul>
  </nav>
}