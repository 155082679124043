import React from 'react';
import InnerInput from './innerInput.js'
import {getElemByClass, hasClass, addClass, removeClass} from '../../components/className.js'
import {copyObject} from '../../components/copyObject.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Synonyms from './synonyms.js'
import SkillAlert from './skillAlert'

export default class LeftBar extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      containedPhrase: null
    }

    this.handleStateSet = this.handleStateSet.bind(this)
    this.setSynonymHandler = this.setSynonymHandler.bind(this)
    this.handleSkillTypeClick = this.handleSkillTypeClick.bind(this)
    this.returnSkill = this.returnSkill.bind(this)
    this.changeSkillVisibility = this.changeSkillVisibility.bind(this)
    this.buildHTML = this.buildHTML.bind(this)

    this.drop = this.drop.bind(this)
    this.drag = this.drag.bind(this)
    this.dragOver = this.dragOver.bind(this)
    this.dragEnter = this.dragEnter.bind(this)
    this.dragLeave = this.dragLeave.bind(this)
    this.dropAsSynonim = this.dropAsSynonim.bind(this)
    this.elemMouseMouve = this.elemMouseMouve.bind(this)
    
    this.onSkillClick = this.onSkillClick.bind(this)
    this.onSkillContextMenu = this.onSkillContextMenu.bind(this)

    this.handleContainedPhrasesCheckbox = this.handleContainedPhrasesCheckbox.bind(this)

    this.handlePointerDown = this.handlePointerDown.bind(this)
    this.handleDragEnd = this.handleDragEnd.bind(this)
    this.ref = React.createRef()
  }

  handleStateSet(data) {
    this.props.stateHandler(data)
  }

  setSynonymHandler(keys, newRaw) {
    this.props.updateRaw(keys, newRaw)
  }

  handleSkillTypeClick(event) {
    this.props.skillTypeClickHandler(event)
  }

  handleContainedPhrasesCheckbox(event) {
    event.target.checked ? this.changeContainedPhrase(event.target.value) : this.changeContainedPhrase(null)
  }

  changeContainedPhrase(value){
    this.handleStateSet({containedPhrase: value})
  }

  returnSkill(key, parent){
    if (key !== parent && parent !== '' && parent !== undefined) {
      const copiedState = copyObject(this.props[parent])

      delete copiedState[key]

      this.handleStateSet({[parent]: copiedState})
      this.changeSkillVisibility(key, true)
    }
  }

  changeSkillVisibility(key, value) {
    this.handleStateSet({
      raw: {
        ...this.props.raw,
        [key]: {
          ...this.props.raw[key],
          visible: value,
        },
      },
    });
  }

  elemMouseMouve(e){
    console.log(e.clientX, e.clientY)
  }

  handlePointerDown(e){
    addClass(e.target, 'dragging')
  };

  handleDragEnd(e){
    removeClass(e.target, 'dragging')
  };

  dragOver(ev) {
    ev.preventDefault();
  }

  dragEnter(e) {
    addClass(e.target, 'dragOver')
  }

  dragLeave(e) {
    removeClass(e.target, 'dragOver')
  }

  drop(e) {
    e.preventDefault();
    const key = e.dataTransfer.getData("key");
    const parent = e.dataTransfer.getData("parent");
    const isFromMain = e.dataTransfer.getData("from_main");
    
    if (isFromMain !== 'true') {
      this.returnSkill(key, parent)
      removeClass(e.target, 'dragOver')
    }
  }


  drag(ev) {
    ev.dataTransfer.setData("key", ev.target["id"]);
  }

  dropAsSynonim(ev) {
    ev.preventDefault()

    const key = ev.dataTransfer.getData("key")
    const isFromMain = ev.dataTransfer.getData("from_main");
    
    if (isFromMain !== 'true') {
      let elem = ev.target
      let parentKey = elem.dataset.key
      const parentZone = ev.target.dataset.parent
      const containedPhrase = key === this.state.containedPhrase ? null : this.state.containedPhrase
      
      if (key !== parentKey && ['specialisations', 'profession_synonyms'].indexOf(parentZone) === -1) {
        if (parentKey === undefined){
          while(parentKey === undefined) {
            elem = elem.parentElement
            parentKey = elem.dataset.key
          }
        }

        if (key !== parentKey) {
          const item = copyObject(this.props.raw[parentKey])
          let synonyms = item.synonyms !== undefined ? item.synonyms : []
          synonyms.push(key)

          this.props.stateHandler({
            raw: {
              ...this.props.raw,
              [parentKey]: {
                ...this.props.raw[parentKey],
                synonyms: synonyms,
              },
              [key]: {
                ...this.props.raw[key],
                visible: false,
              }
            },
            containedPhrase: containedPhrase
          })
        }
      }
    }
  }

  sortObj(obj, by, order){
    return Object.fromEntries(
      Object.entries(obj).sort(([,a],[,b]) => {
        if (by === 'count') {
          if (
            (order === 'DESC' && Number(a[by]) < Number(b[by])) ||
            (order === 'ASC' && Number(a[by]) > Number(b[by]))
          ) {
            return 1;
          } else if (
            (order === 'DESC' && Number(a[by]) > Number(b[by])) ||
            (order === 'ASC' && Number(a[by]) < Number(b[by]))
          ) {
            return -1;
          } else {
            return 0
          }
        } else {
          return order === 'ASC' ? 
            ('' + a[by]).localeCompare(b[by]) : 
            ('' + b[by]).localeCompare(a[by])
        }
      })
    )
  }

  onSkillClick(e){
    if (e.detail === 2) {
      if (this.props.raw[e.target.id]) {
        this.handleStateSet({
          raw: {
          ...this.props.raw,
          [e.target.id]: {
            ...this.props.raw[e.target.id],
            updating: true,
          },
        }})
      }
    }
  }

  onSkillContextMenu(e){
    e.preventDefault()
    if (e.nativeEvent.button === 2) {
      let elem = getElemByClass(e.target, 'skillBlock')

      if(hasClass(elem, 'opened')){
        removeClass(elem, 'opened')
      } else {
        addClass(elem, 'opened')
      }
    }
  }

  isPhraseContain(value){
    if (this.props.containedPhrase !== null){
      return value.includes(this.props.containedPhrase) || this.props.containedPhrase.includes(value)
    }

    return true
  }

  buildHTML() {
    const object = this.sortObj(this.props.raw, this.props.sort, this.props.order)
    
    return Object.keys(object).map((key) => {
      const data = this.props.raw[key];
      const colorCss = data.color || "";
      const type = data.type ? data.type.slice(0, 2) : "?";
      const visible = data.visible === undefined || data.visible === true ? "" : "hidden";
      const count = data.count || 0
      const containedVisibility = this.isPhraseContain(key) ? "" : "notContained"
      const blockClass = `skillBlock ${colorCss} ${visible} ${containedVisibility}`;
      const skillType = <button
        data-key={key}
        onClick={this.handleSkillTypeClick}
        className="elemTypeBtn"
      >
        {type}
      </button>
      

      const data_blacklist = ['children', 'alert', 'job_ids', 'professions_names', 'professions']
      const data_keys_to_show = []

      Object.keys(data).forEach(el => {
        if (!data_blacklist.includes(el)) {
          if (data[el]) {
            data_keys_to_show.push(el)
          }
        }
      })

      const skillInfo = <div className="skillBlock__info">
        {data_keys_to_show.map(attr => {
          return <React.Fragment key={attr}>
            <div><span>{attr}</span></div>
            {
              attr === 'synonyms' ?
              <div>
                <Synonyms 
                  keys={data[attr]} 
                  raw={this.props.raw} 
                  parent={key}
                  handler={this.handleStateSet}
                />
              </div>
              :
              <div><span>{data[attr].toString()}</span></div>
            }
          </React.Fragment>
        })}
      </div>
      const containedPhrasesCheckbox = <div 
        className="skillBlock__checkbox"
        style={{
          opacity: this.props.containedPhrase === key ? '1' : ''
        }}
      >
        <input 
          type="checkbox" 
          name="contained_phrases" 
          value={key} 
          id={`ch_${key}`} 
          onChange={this.handleContainedPhrasesCheckbox} 
          checked={this.props.containedPhrase === key}
        />
        <label 
          htmlFor={`ch_${key}`}
          style={{
            display: this.props.containedPhrase === null || this.props.containedPhrase === key ? '' : 'none',
          }}
        >
          <FontAwesomeIcon icon={icon({name: 'link', style: 'solid'})} />
        </label>
      </div>

      const alert = data.alert
      const alertSolved = alert !== null && alert?.nodes?.length === alert?.nodes?.filter(n => n.solved === true).length
      
      return (
        <div
          draggable="true"
          id={key}
          key={key}
          data-key={key}
          data-count={count}
          onPointerDown={this.handlePointerDown}
          onDragEnd={this.handleDragEnd}
          onDragStart={this.drag}
          onDragEnter={this.dragEnter}
          onDragLeave={this.dragLeave}
          onContextMenu={this.onSkillContextMenu}
          onClick={this.onSkillClick}
          onDrop={this.dropAsSynonim}
          className={blockClass}
        >
          {
            (alert !== undefined && !alertSolved) && 
            <SkillAlert 
              data={data.alert}
              skill={key} 
              raw={this.props.raw}
              skills={this.props.skills}
              stateHandler={this.handleStateSet}
              synonymHandler={this.setSynonymHandler}
            />
          }
          {
            data.updating ? 
            <InnerInput
              id={key}
              name={data.name}
              raw={this.props.raw}
              stateHandler={this.handleStateSet}
            /> :
            <div className="skillBlock__title">
              {data.name}
              <span className="count">{count}</span>
            </div>
          }
          {skillInfo}
          {skillType}
          {containedPhrasesCheckbox}
        </div>
      );
    });
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.sort !== this.props.sort && prevProps.order !== this.props.order) {
      this.ref.current.scrollTo(0, 0);
    }
  }

  render() {
    return (<div 
      className="leftBar__skillsList" 
      onDrop={this.drop}
      onDragOver={this.dragOver}
      ref={this.ref}
    >
      {
        this.props.editReady === true && this.buildHTML()
      }
    </div>)
  }
}