const filterSkills = (db_skills) => {
  const skills = {}

  Object.keys(db_skills).forEach((key) => {
    const name = db_skills[key].name
    let parents = []
    let synonyms = []
    let professions = db_skills[key].professions

    Object.keys(professions).forEach(prof_key => {
      const elem = professions[prof_key]

      parents.push(elem.parent)
      if (Array.isArray(elem.synonyms) && elem.synonyms.length > 0) synonyms = synonyms.concat(elem.synonyms)
    })

    parents = parents.filter((key, i, arr) => arr.indexOf(key) === i && key !== undefined && key !== "")
    synonyms = synonyms.filter((key, i, arr) => arr.indexOf(key) === i && key !== undefined && key !== "")

    skills[key] = {
      name: name,
      parents: parents,
      synonyms: synonyms
    }
  })

  return skills
}

export const buildGlobalTree = (db_skills) => {
  const skills = filterSkills(db_skills)
  

  return skills
}