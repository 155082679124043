import React, { useState, useEffect, useRef } from 'react'
import { convertMinutesToStr } from '../../components/convertMinutesToStr.js'

export default function Number({ data, dataKey, dataHandler }) {
  const [value, setValue] = useState(0)
  const [edit, setEdit] = useState(false)
  const editClass = edit ? 'edit' : ''
  const inputEl = useRef(null)

  const changeView = () => { setEdit(!edit) }
  const blurHandler = (e) => {
    dataHandler(dataKey, onlyDigit(e.target.value))
    changeView()
  }
  const onlyDigit = (value) => { return parseFloat(value.replace(/,/g, '').replace(/^[^-0-9]*/, '')) }
  const changeHandler = (e) => { setValue(onlyDigit(e.target.value)) }
  const keyDownHandler = (e) => { if (e.key === 'Enter') inputEl.current.blur();}
  const clickHandler = () => { if (edit === false) setEdit(true); }

  useEffect(() => {
    setValue(data)
  }, [data])

  useEffect(() => {
    if (edit === true) {
      inputEl.current.focus();
      inputEl.current.value = value;
    }
  }, [edit, value])

  return <div className={`courseEdit__number ${editClass}`} onClick={clickHandler}>
    <span>{value}</span>
    <input
      type="text"
      ref={inputEl}
      onChange={changeHandler}
      onBlur={blurHandler}
      onKeyDown={keyDownHandler}
      placeholder="Type number here..."
    />
    {dataKey === 'duration' && <div className="durationStr">~ {convertMinutesToStr(value)}</div>}
  </div>
}