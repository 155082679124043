import React, { useEffect } from 'react';
// import Modal from '../classes/skills_editor/modal.js'



const Reload = () => {
  const initBeforeUnLoad = () => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = 'Do you want to reload the page?'
      }

      return 'Do you want to reload the page?';
    };
  };

  useEffect(() => {
    initBeforeUnLoad();
  });

  return <></>
}

export default Reload