export const dataType = (elem, type) => {
  switch (type) {
    case 'string':
      return typeof elem === 'string'
      
    case 'number':
      return typeof elem === 'number'

    case 'boolean':
      return typeof elem === 'boolean'

    case 'null':
      return typeof elem === 'object' && elem === null

    case 'array':
      return typeof elem === 'object' && Array.isArray(elem)

    case 'object':
      return typeof elem === 'object' && !Array.isArray(elem) && elem !== null
  
    default:
      return false;
  }
}