import { firebaseAppCourses } from '../firebase'
import { getDatabase, ref, get, onValue, child,query, orderByChild, limitToFirst, runTransaction } from "firebase/database";

export async function getCoursesPathList(){
  const dbRef = ref(getDatabase(firebaseAppCourses));
  return await get(child(dbRef, `courses_path_list`)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      const list = data.list

      return Object.keys(list).map((id) => {
        return {id: id, key: list[id].path, name: list[id].path.replace('/', ' - ')}
      })
    } else {
      return []
    }
  }).catch((error) => {
    console.log(error)
    return []
  });
}

export async function getCourses(path) {
  const data = []
  const dbRef = query(ref(getDatabase(firebaseAppCourses), `courses/${path}`), orderByChild("language"), limitToFirst(100))

  onValue(dbRef, (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      
      data.push({
        id: childKey,
        data: childData
      })
    });
  }, {
    onlyOnce: false
  });

  return data
}

export async function deleteCourseFromRealtimeDB(path){
  const courseRef = ref(getDatabase(firebaseAppCourses), `courses/${path}`)

  return true

  // return await set(courseRef, null).then(() => {
  //   return true
  // }).catch((err) => {
  //   console.log(err)
  //   return false
  // })
}

export async function getCoursesCount(path_id){
  const dbRef = ref(getDatabase(firebaseAppCourses));
  return await get(child(dbRef, `courses_path_list/list/${path_id}`)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      
      return data.count
    } else {
      return 0
    }
  }).catch((error) => {
    console.log(error)
    return -1
  });
}

export async function decreaseCount(pathId){
  const db = getDatabase(firebaseAppCourses);
  const pathRef = ref(db, `courses_path_list/list/${pathId}`);
  
  return await runTransaction(pathRef, (path) => {
    if (path) {
      if (path.count && path.count > 0) {
        path.count--;
      }
    }

    return path;
  }).then((data) => {
    const snap = data.snapshot.val()

    return snap.count
  }).catch((error) => {
    console.log(error)
    return null
  })
}