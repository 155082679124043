import React from 'react';
import logo from "../../img/logo.svg"
import Reload from '../../components/Reload'
import {getCourses, getCoursesCount, deleteCourseFromRealtimeDB, decreaseCount} from '../../db/coursesRealtimeDB.js'
import {uploadCourseToFirestore} from '../../db/coursesFirestore.js'
import CoursesList from './coursesList.js'
import CourseZone from './courseZone.js'


export default class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pathId: this.props.pathId,
      path: this.props.path,
      currentId: null,
      courses: [],
      count: 0
    };

    this.setStateHandler = this.setStateHandler.bind(this)
    this.saveCourse = this.saveCourse.bind(this)
    this.deleteCourse = this.deleteCourse.bind(this)
  }

  setStateHandler(data){
    if (typeof data === 'object') {
      this.setState(data)
    }
  }
  
  filterCoursesData(arr){
    return arr.filter((obj) => {
      return typeof obj['data'] === 'object'
    });
  }
  
  prepareCourses(data, count, currentId = null){
    const courses = this.filterCoursesData(data)
    
    this.setState({
      currentId: currentId,
      courses: courses,
      count: count
    })
  }

  async loadCourses(){
    const courses = await getCourses(this.state.path)

    this.prepareCourses(courses, this.state.count, this.state.currentId)
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.courses.length !== this.state.courses.length) {
      if (this.state.count > 0 && (this.state.courses.length === 0)){
        await this.loadCourses()
      }
    }
  }

  async componentDidMount() {
    const courses = await getCourses(this.state.path)
    const count = await getCoursesCount(this.state.pathId)

    this.prepareCourses(courses, count)
  }
  
  makeCoursePath(courseId){
    return this.state.path + '/' + courseId
  }

  updateState(count, deletedCourseId){
    const courses = Object.assign([], this.state.courses).filter(course => course['id'] !== deletedCourseId)
    const currentId = Number(this.state.currentId) < this.state.courses.length - 1 ? this.state.currentId : this.state.courses.length - 1
    
    this.setState({
      currentId: currentId,
      courses: courses,
      count: count
    })
  }

  saveCourse(courseId, courseData){
    const pathToDelete = this.makeCoursePath(courseId)
    const pathId = this.state.pathId

    uploadCourseToFirestore(courseData)
      .then(() => {
        deleteCourseFromRealtimeDB(pathToDelete)
          .then(() => {
            decreaseCount(pathId)
              .then((count) => {
                this.updateState(count, courseId)
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
      }).catch((err) => {
        console.log(err)
      })
  }

  deleteCourse(courseId){
    const pathToDelete = this.makeCoursePath(courseId)
    const pathId = this.state.pathId

    deleteCourseFromRealtimeDB(pathToDelete)
      .then(() => {
        decreaseCount(pathId)
          .then((count) => {
            this.updateState(count, courseId)
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
      <section className={`mainSection`}>
        <div className="leftBar">
          <img src={logo} alt="Logo" className="leftBar__logo" />
          <div className="leftBar__menu">
            <div className="leftBar__menu__block">
              <p>Data of <br/><b>{this.state.path.replace("/", " - ")}</b></p>
            </div>
            <div className="leftBar__menu__block">
              <span>Courses left:</span><br />
              <b>{this.state.count}</b>
            </div>
          </div>
          <CoursesList 
            data={this.state.courses} 
            currentId={this.state.currentId}
            stateHandler={this.setStateHandler}
          />
        </div>
        <div className="courseEdit">
          {
            this.state.courses.length > 0 && this.state.currentId !== null ? 
            <CourseZone 
              data={this.state.courses[this.state.currentId]} 
              saveHandler={this.saveCourse} 
              deleteHandler={this.deleteCourse} 
            /> 
            : 
            <p>No data</p>
          }
        </div>
        <Reload />
      </section>
    );
  }
}

