import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { saveData } from '../../db/professionsSandbox'

export default class SaveButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'ready',
    }

    this.handleClick = this.handleClick.bind(this)
  }

  isProd(){
    return !window.location.href.includes('localhost')
  }

  handleClick(e){
    this.setState({status: 'load'})

    if (this.isProd() || !this.isProd()) {
      saveData({
        profession: this.props.profession, 
        parent_career: this.props.parent_career,
        name: this.props.name,
        raw: this.props.raw, 
        deleted: this.props.deleted, 
        stops: this.props.stops, 
        tabs: this.props.tabs,
        specialisations: this.props.specialisations,
        profession_synonyms: this.props.profession_synonyms
      }, (error, res) => {
        if (!error) {
          this.showResponse('saved')
        } else {
          console.log(error)
          this.showResponse('error')
        }
      })
    } else {
      this.showResponse('saved')
      console.log('dev mode')
    }
  }

  showResponse(res){
    this.setState({status: res})

    setTimeout(() => {
      this.setState({status: 'ready'})
    }, 3000)
  }

  render(){
    let btnIcon = ''
    let btnText = ''
    let btnDisabled = ''

    switch (this.state.status){
      case 'ready':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'cloud-arrow-up', style: 'solid'})} />
        btnText = 'save'
        btnDisabled = false
        break;

      case 'load':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'spinner', style: 'solid'})} spin />
        btnText = 'saving'
        btnDisabled = true
        break;

      case 'error':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'times', style: 'solid'})} />
        btnText = 'error'
        btnDisabled = true
        break;

      case 'saved':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'check', style: 'solid'})} />
        btnText = 'saved'
        btnDisabled = true
        break;

      default:
        break;
    }
      

    return <button className={`actionBtn ${this.state.status}`} onClick={this.handleClick} style={{'--level': this.props.level}} disabled={btnDisabled}>
      {btnIcon}
      {btnText}
    </button>
  }
}