import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const LogoutBtn = () => {
  const { logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout()
      navigate('/');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <button className="leftBar__logoutBtn" onClick={handleLogout}>
      <i className="fi fi-rr-sign-out-alt"></i>
      log out
    </button> 
  );
};

export default LogoutBtn;