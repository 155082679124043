// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1YwvzPIO6pV6RPwmmYG9khmNVz5lgJSo",
  authDomain: "collabas-cf105.firebaseapp.com",
  databaseURL: "https://collabas-cf105-e25e0.firebaseio.com",
  // databaseURL: "https://collabas-cf105-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "collabas-cf105",
  storageBucket: "collabas-cf105.appspot.com",
  messagingSenderId: "495564272227",
  appId: "1:495564272227:web:f00173aa4b1b09c642be56",
  measurementId: "G-22FT5P7HYQ"
};

const firebaseCoursesConfig = {
  apiKey: "AIzaSyAKFCtf2UkhIbZDYnAbCtTkFjzdVLcdOEI",
  authDomain: "collabas-courses.firebaseapp.com",
  databaseURL: "https://collabas-courses-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "collabas-courses",
  storageBucket: "collabas-courses.appspot.com",
  messagingSenderId: "854389079581",
  appId: "1:854389079581:web:355dea698f8d4d7aa250f4",
  measurementId: "G-J9P6EL5Y0K"
}


export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
// export const db = getFirestore();
export const db = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true
});
export const firebaseAppCourses = firebase.initializeApp(firebaseCoursesConfig, "courses");
// export const dbCourses = getFirestore(firebaseAppCourses);
export const dbCourses = initializeFirestore(firebaseAppCourses, {
  experimentalAutoDetectLongPolling: true
});
export const authCourses = getAuth(firebaseAppCourses);
export const storageCourses = getStorage(firebaseAppCourses);