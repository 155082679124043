import { firebaseApp } from '../firebase'
import { getDatabase, ref, get, child, update, set, query, equalTo, orderByChild, onValue } from "firebase/database";
import {copyObject} from '../components/copyObject.js'

export const getJobIdsData = async (profession) => {
  const dbRef = ref(getDatabase(firebaseApp));
  return await get(child(dbRef, `sandbox/${profession}/raw`)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      const result = {}
      
      Object.keys(data).forEach((key) => {
        const value = data[key]

        if (Array.isArray(value["job_ids"])) {
          result[key] = value["job_ids"]
        }
      })

      return result
    } else {
      return {}
    }
  }).catch((error) => {
    return {}
  });
}

export async function getFamilyProfessions(profession){
  const dbRef = ref(getDatabase(firebaseApp));

  return await get(child(dbRef, `professions`)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      
      return Object.keys(data).filter((key) => {
        return data[key].family === data[profession]?.family;
      });
    } else {
      return []
    }
  }).catch((error) => {
    return []
  });
}

async function fetchData(path, type){
  const types = {
    "object": {},
    "list": [],
    "string": ""
  }
  const dbRef = ref(getDatabase(firebaseApp));

  return await get(child(dbRef, path)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      
      return data || types[type]
    } else {
      return types[type]
    }
  }).catch((error) => {
    return types[type]
  });
}

export async function getProfessions(){
  const sandboxKeysData = await fetchData('sandbox_keys', 'object');

  const promises = Object.keys(sandboxKeysData).map(async (key) => {
    const { name } = sandboxKeysData[key];
    const status = await fetchData(`sandbox/${key}/status`, 'string');
    const parent_career = await fetchData(`sandbox/${key}/parent_career`, 'string');

    return {
      key,
      name,
      status,
      parent_career,
    };
  });

  const result = await Promise.all(promises);
  return result;
}

export async function getSandbox(profession){
  const dbRef = ref(getDatabase(firebaseApp));
  return await get(child(dbRef, `sandbox/${profession}`)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      const result = {}

      Object.keys(data).forEach(key => {
        const value = data[key]

        if (Array.isArray(value['job_ids'])) {
          delete value['job_ids']
        }

        result[key] = value
      })

      return result
    } else {
      return {}
    }
  }).catch((error) => {
    return {}
  });
}

const filterRaw = (data) => {
  let raw = {}

  Object.keys(data).forEach(key => {
    let value = data[key]

    if (Array.isArray(value.children)) delete value.children;
    if (Array.isArray(value.synonyms)) value.synonyms = value.synonyms.filter((k, i, arr) => arr.indexOf(k) === i);

    raw[key] = value
  })

  return raw
}

async function updateProfession(profession, specialisations, profession_synonyms) {
  const db = getDatabase(firebaseApp);
  
  get(query(ref(db, `professions`), orderByChild('name'), equalTo(profession)))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        
        const id = Object.keys(value)[0]
        const val = value[id]
        
        val.experience_specialisations = Object.keys(specialisations) || []
        val.profession_synonyms = Object.keys(profession_synonyms) || []

        set(ref(db, `professions/${id}`), val).then(() => {
          return true
        }).catch((err) => {
          console.log(err)
        })
      }
    })
    .catch((error) => {
      console.log(error)
    });
}

export async function saveData(data, callback){
  const db = getDatabase(firebaseApp);
  const raw = filterRaw(copyObject(data.raw))
  const tabs = copyObject(data.tabs)

  const updates = {};

  for (let key in raw) {
    let value = raw[key]

    Object.keys(value).forEach(prop => {
      if (value[prop] !== "" && value[prop] !== undefined && value[prop] !== null) {
        if (['job_ids', 'alert'].includes(prop) === false) {
          updates[`sandbox/${data.profession}/raw/${key}/${prop}`] = value[prop]
        }
      }
    })
  }

  updates[`sandbox/${data.profession}/tabs`] = tabs
  updates[`sandbox/${data.profession}/deleted`] = data.deleted
  updates[`sandbox/${data.profession}/stops`] = data.stops
  updates[`sandbox/${data.profession}/specialisations`] = data.specialisations
  updates[`sandbox/${data.profession}/profession_synonyms`] = data.profession_synonyms
  
  
  
  update(ref(db), updates)
    .then(() => {
      console.log('Updated')

      saveStops(data.stops)
      updateProfession(data.profession, data.specialisations, data.profession_synonyms)
      callback(undefined, true)
    })
    .catch(error => {
      callback(error, false)
    })
    
}

export async function updateProfessionStatus(profession, value, callback){
  const db = getDatabase(firebaseApp);

  set(ref(db, `sandbox/${profession}/status`), value)
    .then(() => {
      callback(undefined, true)
    })
    .catch(error => {
      callback(error)
    })
}


export async function saveStops(data){
  const db = getDatabase()
  const updates = {}
  
  Object.keys(data).forEach(key => {
    updates[`/stop_words/${key}`] = data[key]
  })

  update(ref(db), updates)
    .then(res => {
      return true
    })
    .catch(err => {
      return err
    })
  
}

export async function professionChildrenCorrectTrash(profession){
  console.log("Moving trash...")
  const professionName = profession.replace(/_v\d+/g, '')
  const version_pattern = profession.match(/_v\d+/);
  const sandbox_version = version_pattern ? version_pattern[0] : ''
  const professions = await getProfessions()
  const professionsToCorrect = professions.filter(prof => prof.parent_career === professionName)

  if (professionsToCorrect.length > 0) {
    const db = getDatabase(firebaseApp);
    const updates = {};

    const parentSandbox = await getSandbox(profession)
    const parentDeleted = parentSandbox["deleted"]
    const parentStops = parentSandbox["stops"]

    for (let childProf of professionsToCorrect) {
      const sandboxName = childProf["key"]
      const sandbox = await getSandbox(sandboxName)

      if (sandbox) {
        // const raw = sandbox["raw"]

        Object.keys(parentDeleted).forEach(key => {
          updates[`sandbox/${sandboxName}/raw/${key}/visible`] = false
          updates[`sandbox/${sandboxName}/deleted/${key}`] = parentDeleted[key] || key
        })

        Object.keys(parentStops).forEach(key => {
          updates[`sandbox/${sandboxName}/raw/${key}/visible`] = false
          updates[`sandbox/${sandboxName}/stops/${key}`] = parentStops[key] || key
        })
      }
    }


    if (Object.keys(updates).length > 0) {
      console.log(`Children updates to make - ${Object.keys(updates).length}`)
      update(ref(db), updates)
        .then(() => {
          return true
        })
        .catch(error => {
          return error
        })
    } else {
      return true
    }
  } else {
    return true
  }
}