import React, { useState, useEffect } from 'react'

export default function Select({ dataKey, value, handler}){
  const [selected, setSelected] = useState("")
  const [options, setOptions] = useState([])
  
  
  const getOptions = (key, value) => {
    let obj = {
      'language': ['english', 'french', 'spanish', 'russian'],
      'courseType': ['online', 'offline', 'hybrid'],
      'lessonType': ['video', 'presentation', 'article'],
    }
    
    if (key === 'language') {
      if (value !== '' && obj[key].indexOf(value) === -1) {
        obj[key].push(value)
      }

      return obj[key]
    } else if (key === 'type') {
      if (obj['courseType'].indexOf(value) >= 0){
        return obj['courseType']
      } else if (obj['lessonType'].indexOf(value) >= 0) {
        return obj['lessonType']
      } else {
        return obj['courseType'].concat(obj['lessonType'])
      }
    }

    return []
  }


  const handleChange = (e) => {
    handler(dataKey, e.target.value)
  }

  useEffect(() => {
    setSelected(value)
    setOptions(getOptions(dataKey, value))
  }, [dataKey, value])

  return <select name={dataKey} value={selected} onChange={handleChange}>
    <option value="">Select {dataKey}</option>
    {options.map((value, i) => {
      return <option value={value} key={i}>{value}</option>
    })}
  </select>
}