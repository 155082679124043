import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {copyObject} from '../../components/copyObject.js' 

export default class SkillAlert extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
      nodeId: null
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)

    this.handleStateSet = this.handleStateSet.bind(this)
  }
  
  handleStateSet(data) {
    this.props.stateHandler(data)
  }
  
  handleSynonymSet(keys, newRaw) {
    keys = keys.filter(k => k !== 'other')
    this.props.synonymHandler(keys, newRaw)
  }

  handleClick(e){
    this.setState({
      isOpened: !this.state.isOpened
    })
  }

  handleChange(e){
    const value = e.target.value
    const name = e.target.name

    this.setState({
      [name]: value
    })
  }

  submitForm(nodeId, type, data){
    if (type === 'synonym') {
      const raw = copyObject(this.props.raw)
      const skills = copyObject(this.props.skills)
      const synonyms = data
      let alert = copyObject(this.props.data)

      if (nodeId !== null) {
        alert.nodes[nodeId].solved = true
        // console.log(alert.nodes)
        // alert.nodes = alert.nodes.filter((id, n, arr) => {
        //   return arr[id].solved !== true
        // })

        // console.log(alert.nodes, alert.nodes.length)
        // return false
        if (this.state.allNodes === this.state.solvedNodes + 1) {
          alert = null
        }
      }
      
      if (Array.isArray(synonyms)) {
        synonyms.forEach(synonym => {
          const parentKey = synonym
          let parentData = {}
          
          if (parentKey !== 'other'){

            // Parent skill exist in cloud DB
            if (skills[parentKey] !== undefined) {
              parentData = copyObject(skills[parentKey])
              parentData["color"] = "green"
              parentData["visible"] = true
            } else {

              // Parent skill NOT exist in main RAW object
              if (raw[parentKey] === undefined) {
                parentData = {
                  name: parentKey.replaceAll('_', ' '),
                  color: "green",
                  isExist: true,
                  visible: true,
                  synonyms: [],
                  type: this.props.skills[parentKey].type || ""
                }
              } else {
                parentData = copyObject(raw[parentKey])
              }
            }

            // Set synonym value
            const skillName = raw[this.props.skill].name ?? this.props.skill

            // Append new synonym and set correct synonyms data
            parentData.synonyms = Array.isArray(parentData.synonyms) ? parentData.synonyms.concat([skillName]) : [skillName]
            parentData.synonyms = parentData.synonyms.filter((key, index, arr) => {
              return arr.indexOf(key) === index
            })

            // Set correct type
            parentData.type = parentData.type === undefined ? "" : parentData.type

            // Set data to main object
            raw[parentKey] = parentData
            raw[this.props.skill].alert = alert

            // Sometimes skills keys are the same. The difference only is "name"
            if (parentKey !== this.props.skill) {
              raw[this.props.skill].visible = false
              raw[this.props.skill].isSynonym = true
            }
          } else {
            // Checked "other"
            raw[this.props.skill].alert = alert
            raw[this.props.skill].visible = true
          }
        })

        // this.handleStateSet({
        //   raw: raw
        // })

        this.handleSynonymSet(synonyms, raw)

        this.setState({
          isOpened: false
        })
      }
    }
  }

  buildBlock(){
    const allNodes = this.props.data.nodes
    const solvedNodes = allNodes.filter(node => node.solved === true)

    let nodeId = undefined

    for (let id in allNodes) {
      if (allNodes[id].solved !== true) {
        nodeId = id

        break
      }
    }

    if (nodeId !== undefined) {
      const node = allNodes[nodeId]
      const type = node.type
      return <div className="skillAlert__block">
        <span className="skillAlert__identificator">
          {solvedNodes.length + 1}/{allNodes.length}
        </span>
        <span className="skillAlert__identificator">
          word <b>{this.props.skill}</b>
        </span>
        <button className="skillAlert__close" onClick={() => {this.setState({isOpened: false})}}>
          <FontAwesomeIcon icon={icon({name: 'times', style: 'solid'})} />
        </button>
        <Title type={type} />
        <Form nodeId={nodeId} type={type} skill={this.props.skill} options={node.options} submit={this.submitForm} />
      </div>
    }
    
  }

  render(){
    const allNodes = this.props.data.nodes
    const solvedNodes = allNodes.filter(node => node.solved === true)
    const opened = this.state.isOpened ? 'opened' : ''

    if (allNodes.length !== solvedNodes.length) {
      return <div className={`skillAlert ${opened}`}>
        <div className="skillAlert__icon" onClick={this.handleClick}>
          <FontAwesomeIcon icon={icon({name: 'exclamation', style: 'solid'})} />
        </div>
        {this.buildBlock()}
      </div>;
    }

    return ''
  }
}

class Title extends React.Component {
  render() {
    if (this.props.type === 'synonym') {
      return <h4>Set as synonym for:</h4>;
    }
    
    return '' 
  }
}

class Form extends SkillAlert {
  constructor(props) {
    super(props)

    this.state = {
      type: this.props.type
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e){
    const type = e.target.type
    const value = e.target.value
    const name = e.target.name
    const checked = e.target.checked
    
    if (type === 'checkbox') {
      let result = Object.assign([], this.state[name])

      if (checked === true) {
        result.push(value)
      } else {
        result = result.filter(val => val !== value)
      }
      
      this.setState({
        [name]: result
      })
    }
    else if (type === 'radio') {
      this.setState({
        [name]: value
      })
    }
  }

  handleSubmit(e) {
    if (this.state.type === 'synonym') {
      // console.log(this.state.synonym)
      this.props.submit(this.props.nodeId, 'synonym', this.state.synonym)
    }
  }

  render() {
    const options = this.props.options
    const type = this.state.type
    const skill = this.props.skill

    if (Array.isArray(options)) {
      return <div className="skillAlert__form">
        {
          options.filter((key, i, arr) => {
            return arr.indexOf(key) === i
          }).map((key, i) => {
            return <React.Fragment key={i}>
              <input type="checkbox" name={type} value={key} id={`${skill}_${key}`} onChange={this.handleChange} />
              <label htmlFor={`${skill}_${key}`}>{key.replaceAll('_',' ')}</label>
            </React.Fragment>
          })
        }
        <input type="checkbox" name={type} value="other" id={`${skill}_other`} onChange={this.handleChange} />
        <label htmlFor={`${skill}_other`}>other</label>
        <button data-for={type} onClick={this.handleSubmit}>Submit</button>
      </div>;
    }

    return ''
  }
}