import React, { useState, useEffect } from 'react'

export default function Boolean({ data, dataKey, dataHandler }) {
  const [value, setValue] = useState(true)

  const handleClick = (e) => {
    dataHandler(dataKey, !value)
  }

  useEffect(() => {
    setValue(data)
  }, [data])

  return <div onClick={handleClick} className={`courseEdit__boolean ${value}`}>{value ? 'true' : 'false'}</div>
}