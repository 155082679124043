import React, {useState, useEffect} from 'react'
import {normaliseStr} from '../../components/normaliseStr.js'

export default function CoursesList({data, currentId, stateHandler}){
  const [checkedId, setCheckedId] = useState(null)
  const handleChange = (e) => {
    stateHandler({
      currentId: e.target.value
    })
  }
  
  useEffect(() => {
    if (currentId !== null) {
      setCheckedId(currentId)
    }
  }, [currentId])

  const buildCourses = (data) => {
    return data.map((obj,i) => {
      return <React.Fragment key={i}>
        <input type="radio" name="course" value={i} id={`${i}_${obj['id']}`} onChange={handleChange} checked={`${checkedId}` === `${i}`} />
        <label htmlFor={`${i}_${obj['id']}`}>
          {normaliseStr(obj['data'].title)}
        </label>
      </React.Fragment>
  })
  }

  return <div className="leftBar__coursesList">
    {data === null && <span>Loding courses...</span>}
    {data !== null && data.length === 0 && <span>No data</span>}
    {data !== null && data.length > 0 && buildCourses(data)}
  </div>
}