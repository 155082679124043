import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth, authCourses } from '../firebase';
import { isAdmin } from '../db/isAdmin'

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  // const createUser = (email, password) => {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // };

  // const signIn = (email, password) =>  {
  //   return signInWithEmailAndPassword(auth, email, password)
  // }

  const logout = async () => {
    return signOut(auth).then(() => {
      signOut(authCourses)
    })
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        isAdmin(currentUser.email)
          .then((result) => {
            if (result === true) {
              currentUser.isAdmin = true
            }

            setUser(currentUser)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        setUser(currentUser)
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, logout}}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};