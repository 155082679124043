import React from "react"

export default class SpecialisationsZone extends React.Component {
  constructor(props) {
    super(props)

    this.handleStateSet = this.handleStateSet.bind(this)
    this.hideSkillBlock = this.hideSkillBlock.bind(this)
    
    this.replaceSkill = this.replaceSkill.bind(this);

    this.getZone = this.getZone.bind(this);
    this.drop = this.drop.bind(this);
    this.drag = this.drag.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.dragEnter = this.dragEnter.bind(this)
    this.dragLeave = this.dragLeave.bind(this)
  }

  replaceSkill(key, place) {
    this.handleStateSet({
      [place]: {
        ...this.props[place],
        [key]: "",
      },
    })
  }

  getZone(elem) {
    let zone = elem.dataset.zone

    if (zone === undefined){
      while(zone === undefined) {
        elem = elem.parentElement
        zone = elem.dataset.zone
      }
    }

    return zone
  }

  drop(e) {
    const key = e.dataTransfer.getData("key");
    const parent = e.dataTransfer.getData("parent");
    const zone = this.getZone(e.target)

    this.replaceSkill(key, zone)
    this.hideSkillBlock(key)
    this.removeFromPreviousZone(key, parent, zone)
  }

  removeFromPreviousZone(key, previous, next){
    if (previous !== '' && previous !== undefined && previous !== next) {
      const copiedState = this.props[previous]

      delete copiedState[key]

      this.handleStateSet({[previous]: copiedState})
    }
  }

  drag(e) {
    const key = e.target['id']
    const parent = e.target.dataset.parent
    
    e.dataTransfer.setData("parent", parent);
    e.dataTransfer.setData("key", key);
  }

  dragEnd(e) {
    e.preventDefault();
  }

  dragOver(e) {
    e.preventDefault();
  }

  dragEnter(e) {
  }

  dragLeave(e) {
  }

  handleStateSet(data) {
    this.props.stateHandler(data)
  }

  hideSkillBlock(key){
    this.props.skillDataHandler(key, 'visible', false)
  }

  removeFromTrash(key, parent){
    const data = this.props[parent]

    if (data !== undefined && data[key] !== undefined) {
      delete data[key]

      this.handleStateSet({
        [parent]: data
      })
    }
  }

  buildSpecialisations(){
    return <div className="workZone__specialisations__block">
      <h3>Experience specialisations:</h3>
      <div 
        className="workZone__specialisations__box"
        onDrop={this.drop}
        onDragOver={this.dragOver}
        data-zone="specialisations"
      >
        {
          Object.keys(this.props.specialisations).map((key) => {
            const data = this.props.raw[key];
            const name = data ? data.name : key
            const colorCss = data ? data.color : "";
            const blockClass = `skillBlock ${colorCss}`;

            return (
              <div
                className={blockClass}
                key={key}
                id={key}
                draggable="true"
                onDragStart={this.drag}
                onDragEnd={this.dragEnd}
                data-parent="specialisations"
              >
                {name}
              </div>
            );
          })
        }
      </div>
    </div>
  }

  buildSynonyms(){
    return <div className="workZone__specialisations__block">
      <h3>Synonyms of <span>{this.props.profession.replace('_',' ')}</span>:</h3>
      <div 
        className="workZone__specialisations__box"
        onDrop={this.drop}
        onDragOver={this.dragOver}
        data-zone="profession_synonyms"
      >
        {
          Object.keys(this.props.profession_synonyms).map((key) => {
            const data = this.props.raw[key];
            const name = data ? data.name : key
            const colorCss = data ? data.color : "";
            const blockClass = `skillBlock ${colorCss}`;

            return (
              <div
                className={blockClass}
                key={key}
                id={key}
                draggable="true"
                onDragStart={this.drag}
                onDragEnd={this.dragEnd}
                data-parent="profession_synonyms"
              >
                {name}
              </div>
            );
          })
        }
      </div>
    </div>
  }

  render() {
    return (<div className="workZone__specialisations">
      {this.buildSpecialisations()}
      {this.buildSynonyms()}
    </div>)
  }
}