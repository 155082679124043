import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {findPath, updateObject, getAllKeys, deleteKey} from '../../components/objectFunctions.js'
import InnerInput from './innerInput.js'
import Modal from './modal.js'
import Synonyms from './synonyms.js'
import {getElemByClass, hasClass, addClass, removeClass} from '../../components/className.js'
import {copyObject} from '../../components/copyObject.js'

export default class MainZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addParentSkill: false,
      currentTab: null,
      modal: {
        show: false,
        text: "",
        key: "",
        options: []
      },
      highlightedSkill: ""
    }

    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleUpdateTab = this.handleUpdateTab.bind(this)
    this.handleStateSet = this.handleStateSet.bind(this)
    this.handleSkillTypeClick = this.handleSkillTypeClick.bind(this)
    this.handleParentTypeBtnClick = this.handleParentTypeBtnClick.bind(this)
    this.handleRemoveSkillClick = this.handleRemoveSkillClick.bind(this)
    this.handleTopBtnClick = this.handleTopBtnClick.bind(this)
    this.handleModalResponse = this.handleModalResponse.bind(this)
    this.hideSkillBlock = this.hideSkillBlock.bind(this)

    this.buildTabs = this.buildTabs.bind(this);
    this.updateTab = this.updateTab.bind(this)
    this.buildHTML = this.buildHTML.bind(this)
    this.buildElement = this.buildElement.bind(this)

    this.removeSkill = this.removeSkill.bind(this)

    this.drag = this.drag.bind(this)
    this.dragOver = this.dragOver.bind(this)
    this.drop = this.drop.bind(this)
    this.dropAsSynonim = this.dropAsSynonim.bind(this)
    this.dragEnter = this.dragEnter.bind(this)
    this.dragLeave = this.dragLeave.bind(this)
    this.handleDragEnd = this.handleDragEnd.bind(this)

    this.onEmptyElemClick = this.onEmptyElemClick.bind(this)

    this.highLight = this.highLight.bind(this)
  }

  getPropObj(name) {
    const data = this.props[name]

    if (this.props[name] !== undefined) {
      const copiedData = copyObject(data)

      return copiedData
    }

    return {}
  }

  drag(e){
    addClass(e.target, 'dragging')
    e.dataTransfer.setData("from_main", true);
    e.dataTransfer.setData("key", e.target.dataset.id);
  }

  dragOver(ev) {
    ev.preventDefault();
  }

  dragEnter(e) {
    addClass(e.target, 'dragOver')
  }

  dragLeave(e) {
    removeClass(e.target, 'dragOver')
  }

  handleDragEnd(e){
    removeClass(e.target, 'dragging')
  }

  drop(ev) {
    ev.preventDefault();

    const key = ev.dataTransfer.getData("key");
    const parentKey = ev.target.dataset.parent;
    const isFromMain = ev.dataTransfer.getData("from_main");

    
    if (isFromMain === 'true') {
      if (key !== parentKey) {
        const tabs = this.getPropObj('tabs')
        const fullPath = findPath(tabs, key)
        fullPath.push(key)
        
        let skillTree = tabs
        fullPath.forEach(key => {
          skillTree = skillTree[key]
        })

        if (findPath(tabs, parentKey).indexOf(key) === -1) {
          // Remove data
          let data = {
            raw: copyObject(this.props.raw),
            tabs: copyObject(this.props.tabs)
          }

          if (skillTree === '') {
            data = this.getDataForRemoveSkill(key)
          } else {
            data = this.getDataForRemoveSkillAndChildren(key)
          }

          this.moveSkillTree(key, {[key]: skillTree}, parentKey, data)
        }
      }
    } else {
      
      if (this.props.raw[key].isExist === true) {
        // const rootParent = this.getRootParent(key)
        const rootParent = key
        const existingTree = this.buildExistingTree(rootParent)

        this.moveSkillTree(rootParent, existingTree, parentKey)
      } else {
        this.moveSkillBlock(key, parentKey)
      }
      
      this.handleStateSet({containedPhrase: null})
    }
  }
  
  getRootParent(key){
    const tabsKeys = getAllKeys(this.props.tabs)
    let rootParent = key
    let exist = true
    
    while (exist){
      if (
        this.props.raw[rootParent]['general-parent'] !== "" && 
        this.props.raw[rootParent]['general-parent'] !== undefined &&
        (
          this.props.raw[rootParent]['general-parent'].visible === true ||
          this.props.raw[rootParent]['general-parent'].visible === undefined
        ) &&
        tabsKeys.indexOf(this.props.raw[rootParent]['general-parent']) === -1
      ) {
        rootParent = this.props.raw[rootParent]['general-parent'] 
      } else {
        exist = false
      }
    }

    return rootParent
  }

  isSynonymAlertSolved(key){
    if (this.props.raw[key].alert !== undefined) {
      if (Array.isArray(this.props.raw[key].alert.nodes)) {
        const nodes = this.props.raw[key].alert.nodes

        for (let node of nodes) {
          if (node.type === 'synonym') {
            if (node.solved === false || node.solved === undefined) {
              return false
            }
          }
        }
      }
    }

    return true
  }

  buildExistingTree(key){
    return {[key]: this.getExistingChild(key)}
  }

  getExistingChild(key, keys_done = [key]){
    const skill = this.props.raw[key]
    const result = {}
    
    if(skill.children !== undefined && Array.isArray(skill.children)){
      for (let child of skill.children) {
        if (this.props.raw[child] !== undefined && (this.props.raw[child].visible === undefined || this.props.raw[child].visible === true) && this.isSynonymAlertSolved(child)) {
          if (!keys_done.includes(child)){
            keys_done.push(child)
            
            result[child] = this.getExistingChild(child, keys_done)
          }
        }
      }
    }

    return Object.keys(result).length > 0 ? result : ''
  }

  getDuplicatedSkills(obj) {
    const duplicated = []
    const all = getAllKeys(obj)

    for (let key of all) {
      let n = 0

      for (let x of all) {
        if (x === key) {
          n++
        }
      }

      if (n > 1 && duplicated.indexOf(key) === -1) {
        duplicated.push(key)
      }
    }

    return duplicated
  }

  removeDuplicatedKeys(obj, duplicatedKeys) {
    if (typeof obj !== 'object' || obj === null) {
      return obj; // Return non-object values as is
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.removeDuplicatedKeys(item, duplicatedKeys)); // Recursively process array elements
    }

    const updatedObj = {};
    for (const key in obj) {
      if (!duplicatedKeys.includes(key)) {
        updatedObj[key] = this.removeDuplicatedKeys(obj[key], duplicatedKeys); // Recursively process nested objects
      }
    }
    return updatedObj;
  }

  moveSkillTree(rootKey, tree, parent, data = null){
    const tabs = data !== null && data.tabs !== undefined ? data.tabs : copyObject(this.props.tabs)
    const raw = data !== null && data.raw !== undefined ? data.raw : copyObject(this.props.raw)
    const objToSet = {}
    const duplicatedSkills = this.getDuplicatedSkills(tree)
    

    if (this.state.currentTab === null) {
      tabs[rootKey] = tree[rootKey]
      objToSet.tabs = tabs

      this.setState({ currentTab: rootKey})
    } else {
      if (parent !== "") {
        const currentTab = this.state.currentTab;
        const path = findPath(tabs, parent);
        path.push(parent);

        let newObj = updateObject(tabs, path, rootKey, tree[rootKey]);
        
        tabs[currentTab] = newObj[currentTab]
        objToSet.tabs = tabs
      }
    }

    // Move all duplicated skills back to left bar
    if (duplicatedSkills.length > 0) {
      const updated_tabs = this.removeDuplicatedKeys(tabs, duplicatedSkills)
      tree = this.removeDuplicatedKeys(tree, duplicatedSkills)

      objToSet.tabs = updated_tabs
    }
    
    objToSet.raw = this.hideSkillsTree(tree, raw)

    this.handleStateSet(objToSet)
  }

  moveSkillBlock(key, parent) {
    if (key !== '' && key !== ' ') {
      this.updateTab(key, parent);
      this.hideSkillBlock(key)
    }
  }

  dropAsSynonim(ev) {
    ev.preventDefault()

    const key = ev.dataTransfer.getData("key")
    let elem = ev.target
    let parentKey = elem.dataset.key

    if (parentKey === undefined){
      while(parentKey === undefined) {
        elem = elem.parentElement
        parentKey = elem.dataset.key
      }
    }
    
    if (key !== parentKey) {
      const synonyms = this.props.raw[parentKey].synonyms || []
      synonyms.push(key)

      this.props.stateHandler({
        raw: {
          ...this.props.raw,
          [parentKey]: {
            ...this.props.raw[parentKey],
            synonyms: synonyms,
          },
          [key]: {
            ...this.props.raw[key],
            visible: false,
          }
        },
      })
    }
  }

  handleTabChange(event) {
    this.setState({ currentTab: null });

    if (event.target.id !== "addTabBtn") {
      const value = event.target.value || event.target.dataset.value
      
      this.setState({ currentTab: value });
    }
  }

  handleUpdateTab(key, parent = ""){
    if (key !== '' && key !== ' ') {
      this.updateTab(key, parent)
    }
  }

  updateTab(keyToAdd, parentKey = "") {
    const tabs = this.getPropObj('tabs')
    const objToSet = {}
    

    if (this.state.currentTab === null) {
      objToSet.tabs = {
        ...tabs,
        [keyToAdd]: {},
      }

      this.setState({ currentTab: keyToAdd})
    } else {
      const currentTab = this.state.currentTab;

      if (parentKey !== "") {
        const path = findPath(tabs, parentKey);
        path.push(parentKey);

        let newObj = updateObject(tabs, path, keyToAdd);

        objToSet.tabs = {
          ...tabs,
          [currentTab]: newObj[currentTab],
        }
      } else {
        tabs[keyToAdd] = {
          [currentTab]: tabs[currentTab]
        }
        delete tabs[currentTab];

        objToSet.tabs = tabs

        this.addParentSkill__off()
        this.setState({ currentTab: keyToAdd})
      }
    }

    this.handleStateSet(objToSet)
  }

  buildTabs() {
    const tabs = this.getPropObj('tabs');
    const current = this.state.currentTab;
    

    ['soft','trait'].forEach(tabName => {
      if (tabs[tabName] !== undefined) {
        delete tabs[tabName]
      }
    })

    return Object.keys(tabs).map((key, i) => {
      const checked = key === current;
      
      if (this.props.raw[key]) {
        return (
          <React.Fragment key={i}>
            <input
              type="radio"
              name="tab"
              value={key}
              id={key + i}
              onChange={this.handleTabChange}
              checked={checked}
            />
            <label className="workZone__nav__tab" htmlFor={key + i} data-value={key} onDragOver={this.handleTabChange}>
              {this.props.raw[key].name}
            </label>
          </React.Fragment>
        );
      } else {
        return <></>
      }
    });
  }

  handleStateSet(data) {
    this.props.stateHandler(data)
  }

  handleSkillTypeClick(event) {
    this.props.skillTypeClickHandler(event)
  }

  handleParentTypeBtnClick(e){
    let elem = getElemByClass(e.target, 'parentTypeBtn')
    
    const key = elem.dataset.key
    const parentType = this.props.raw[key].parentType || 'general'
    const newParentType = parentType === 'general' ? 'class' : 'general'

    this.handleStateSet({
      raw: {
        ...this.props.raw,
        [key]: {
          ...this.props.raw[key],
          parentType: newParentType,
        },
      },
    })
  }

  hideSkillBlock(key){
    this.props.skillDataHandler(key, 'visible', false)
  }

  hideSkillsTree(tree, raw){
    getAllKeys(tree).forEach(key => {
      raw[key].visible = false
    })
    
    return raw
  }

  showSkills(arr){
    const obj = this.props.raw
    
    arr.forEach(key => {
      obj[key].visible = true
    })
    
    this.handleStateSet({
      raw: obj
    })
  }

  addParentSkill__on(){
    this.setState({addParentSkill: true})
  }

  addParentSkill__off(){
    this.setState({addParentSkill: false})
  }

  handleTopBtnClick(e){
    this.state.addParentSkill ? this.addParentSkill__off() : this.addParentSkill__on()
  }

  handleModalResponse(option) {
    if (option === 'removeTab') {
      this.removeTab(this.state.modal.key)
    } else if (option === 'removeSkill') {
      this.removeSkill(this.state.modal.key)
    } else if (option === 'removeSkillAndChildren') {
      this.removeSkillAndChildren(this.state.modal.key)
    }

    this.setState({
      modal: {
        text: "",
        show: false,
        key: "",
        options: []
      }
    })
  }

  handleRemoveSkillClick(e){
    const elem = getElemByClass(e.target, 'removeSkillBtn')
    const key = elem.dataset.key
    const currentTab = this.state.currentTab;
    const dataToSet = {}
    
    // Highlight duplicated skill if deleting kind of it
    if (elem.dataset.duplicated === 'true') {
      if (this.state.highlightedSkill !== key) {
        dataToSet.highlightedSkill = key
      }
    }

    if (key === currentTab) {
      // Ask to remove tab
      dataToSet.modal = {
        text: "This option will remove whole group. Are you sure?",
        show: true,
        key: key,
        options: ['removeTab']
      }
    } else if (this.hasChildren(key)) {
      // ask to remove only key or key + children
      dataToSet.modal = {
        text: "Do you want to remove only skill or skill with its children?",
        show: true,
        key: key,
        options: ['removeSkill', 'removeSkillAndChildren']
      }
    } else {
      // ask to remove that key
      dataToSet.modal = {
        text: "Do you want to remove this skill?",
        show: true,
        key: key,
        options: ['removeSkill']
      }
    }

    this.setState(dataToSet)
  }

  hasChildren(key){
    const fullPath = findPath(this.props.tabs, key)
    fullPath.push(key)
    
    let children = this.props.tabs
    fullPath.forEach(k => {
      children = children[k]
    })

    return Object.keys(children).length > 0
  }

  getDataForRemoveSkill(key){
    const raw = copyObject(this.props.raw)
    let tabsObj = this.getPropObj('tabs')
    const path = findPath(this.props.tabs, key)
    const fullPath = findPath(this.props.tabs, key)
    fullPath.push(key)
    
    let children = this.getPropObj('tabs')
    fullPath.forEach(k => {
      children = children[k]
    })

    let newObj = {}

    if (Object.keys(children).length > 0){
      for (let k in children) {
        newObj = updateObject(tabsObj, path, k, children[k]);
      }

      newObj = deleteKey(newObj, fullPath)
    } else{
      newObj = updateObject(tabsObj, path, key, "")
      newObj = deleteKey(newObj, fullPath)
      // newObj = setLastKeyEmpty(newObj, fullPath)
    }

    raw[key].visible = true

    // Remove this key from all skills "children" array
    // Because if key is highlighted this key is duplicated and we need to refresh data
    if (this.state.highlightedSkill === key) {
      for (let k in raw) {
        let data = raw[k]

        if (Array.isArray(data.children) && data.children.includes(key)){
          raw[k].children = data.children.filter(k => k !== key)
        }
      }
    }

    return {
      raw: raw,
      tabs: newObj
    }
  }

  getDataForRemoveSkillAndChildren(key){
    const raw = this.getPropObj('raw')
    const tabs = this.getPropObj('tabs')
    const fullPath = findPath(tabs, key)
    fullPath.push(key)

    // Make object of skill and its children
    let skillTree = tabs
    fullPath.forEach(key => {
      skillTree = skillTree[key]
    })

    // Get all keys arr
    const keys = getAllKeys(skillTree)
    keys.push(key)

    keys.forEach(k => {
      raw[k].visible = true
    })

    let newObj = deleteKey(tabs, fullPath)
    // newObj = setLastKeyEmpty(newObj, fullPath)
    
    return {
      raw: raw,
      tabs: newObj
    }
  }

  getDataForRemoveTab(key){
    const tabs = this.getPropObj('tabs')
    const raw = this.getPropObj('raw')

    const keys = getAllKeys(this.props.tabs[key])
    keys.push(key)

    keys.forEach(k => {
      raw[k].visible = true
    })

    delete tabs[key]

    return {
      raw: raw,
      tabs: tabs
    }
  }

  removeSkill(key){
    const currentTab = this.state.currentTab;

    if (key !== currentTab) {
      const data = this.getDataForRemoveSkill(key)

      this.handleStateSet({
        raw: data.raw,
        tabs: data.tabs
      })
    }
  }

  removeSkillAndChildren(key){
    const currentTab = this.state.currentTab;

    if (key !== currentTab) {
      const data = this.getDataForRemoveSkillAndChildren(key)

      this.handleStateSet({
        raw: data.raw,
        tabs: data.tabs
      })
    }
  }

  removeTab(key){
    const data = this.getDataForRemoveTab(key)

    this.handleStateSet({
      raw: data.raw,
      tabs: data.tabs
    })

    this.setState({
      currentTab: null
    })
  }

  onEmptyElemClick(e) {
    if (e.detail === 1) {
      const elem = e.target

      if(!hasClass(elem, 'input')){
        addClass(elem, 'input')
      } else {
        removeClass(elem, 'input')
      }
    }
  }

  buildEmptyElem(parentKey = ""){
    return <div
      className="workZone__elem empty"
      onDrop={this.drop}
      onDragOver={this.dragOver}
      onDragEnter={this.dragEnter}
      onDragLeave={this.dragLeave}
      onClick={this.onEmptyElemClick}
      data-parent={parentKey}
    >
      <InnerInput
        id=""
        name=""
        raw={this.props.raw}
        tabs={this.props.tabs}
        currentTab={this.props.currentTab}
        stateHandler={this.handleStateSet}
        tabHandler={this.handleUpdateTab}
        placeholder="Enter skill name"
      />
    </div>
  }

  highLight(e) {
    const key = e.target.dataset.key

    if (key !== undefined) {
      const value = this.state.highlightedSkill === key ? "" : key
      this.setState({highlightedSkill: value})
    }
  }

  buildElement(key, data, level, fontsize = 16) {
    const allKeys = getAllKeys(this.props.tabs[this.state.currentTab])
    const skill = this.props.raw[key] || this.props.raw[key.replace(" ", "_")];
    const name = skill.name;
    const type = skill.type ? skill.type.slice(0, 2) : "?";
    const parentType = skill.parentType ? skill.parentType : "general";
    const synonyms = skill.synonyms;
    const children_num = Object.keys(data).length;
    const columns = children_num !== 0 ? children_num : 1;
    const skillType = (
      <button
        data-key={key}
        onClick={this.handleSkillTypeClick}
        className="elemTypeBtn"
      >
        {type}
      </button>
    );

    const topEmptyElem = this.state.addParentSkill === true && level === 0 ? this.buildEmptyElem() : <></>
    const topBtnIcon = this.state.addParentSkill ? <FontAwesomeIcon icon={icon({name: 'minus', style: 'solid'})} /> : <FontAwesomeIcon icon={icon({name: 'plus', style: 'solid'})} />
    const topBtn = level === 0 ? <button 
      className="workZone__elem__topBtn"
      onClick={this.handleTopBtnClick}
    >
      {topBtnIcon}
    </button> : <></> 

    const parentTypeIcon = parentType === 'general' ? 
      <FontAwesomeIcon icon={icon({name: 'chart-pie', style: 'solid'})} /> :
      <FontAwesomeIcon icon={icon({name: 'list-ul', style: 'solid'})} />

    const parentTypeBtn = <button className={`parentTypeBtn ${parentType}`} data-key={key} onClick={this.handleParentTypeBtnClick}>
      {parentTypeIcon}
    </button>

    const tooltip = <div className="workZone__elem__tooltip">{name}</div>
    
    const isDuplicated = allKeys.filter(k => k === key).length > 1
    const duplicateClass = isDuplicated ? "duplicated" : ""
    const highlightedSkill = this.state.highlightedSkill
    const opacitedClass = highlightedSkill !== "" && allKeys.includes(highlightedSkill) && highlightedSkill !== key ? "opacited" : ""
    const onClick = isDuplicated || this.state.highlightedSkill === key ? this.highLight : () => {}

    level++;
    fontsize = level > 1 && fontsize >= 14 ? fontsize - 2 : fontsize;

    return (
      <div 
        className="workZone__col" 
        style={{ "--columns": columns }}
        draggable="true"
        onDragStart={this.drag}
        onDragEnd={this.handleDragEnd}
        data-id={key}
      >
        {topEmptyElem}
          
        <div
          className={`workZone__elem ${duplicateClass} ${opacitedClass}`}
          onDragOver={this.dragOver}
          onDragEnter={this.dragEnter}
          onDragLeave={this.dragLeave}
          onDrop={this.dropAsSynonim}
          data-key={key}
          onClick={onClick}
        >
          {topBtn}
          {tooltip}
          <div className="workZone__elem__name" style={{
            "--fontsize": `${fontsize}px`,
          }}>
            {name}
            {skillType}
          </div>
          {
            synonyms !== undefined && <Synonyms 
              keys={synonyms} 
              raw={this.props.raw} 
              parent={key}
              handler={this.handleStateSet}
            />
          }

          {parentTypeBtn}

          <button className="removeSkillBtn" data-key={key} data-duplicated={isDuplicated} onClick={this.handleRemoveSkillClick}>
            <FontAwesomeIcon icon={icon({name: 'eraser', style: 'solid'})} />
          </button>
        </div>

        {children_num > 0 ? (
          <div
            className="workZone__row"
            style={{
              "--columns": columns,
              "--fontsize": `${fontsize}px`,
            }}
          >
            {Object.keys(data).map((child_key, i) => {
              return (
                <React.Fragment key={i}>
                  {this.buildElement(
                    child_key,
                    data[child_key],
                    level + 1,
                    fontsize
                  )}
                </React.Fragment>
              );
            })}
            {this.buildEmptyElem(key)}
          </div>
        ) : (
          this.buildEmptyElem(key)
        )}
      </div>
    );
  }
  
  buildHTML() {
    const data = this.props.tabs[this.state.currentTab];

    if (['soft','trait'].indexOf(this.state.currentTab) !== -1) {
      return <ExtraSkills data={data} {...this.state} {...this.props} />
    } else {
      if (data !== undefined && this.state.currentTab !== null) {
        return (
          this.buildElement(this.state.currentTab, data, 0)
        )
      } else {
        return (
          this.buildEmptyElem()
        );
      }
    }
  }

  buildExtraTabs() {
    return ['soft', 'trait'].map(key => <React.Fragment key={key}>
      <input
        type="radio"
        name="tab"
        value={key}
        id={key}
        onChange={this.handleTabChange}
        onDragOver={this.handleTabChange}
        checked={this.state.currentTab === key}
      />
      <label className="workZone__nav__tab" htmlFor={key} data-value={key} onDragOver={this.handleTabChange}>{key}</label>
    </React.Fragment>) 
  }

  render() {
    const body = this.buildHTML()
    const extraTabs = this.buildExtraTabs()
    const tabs = this.buildTabs()
    // const total = getAllKeys(this.props.tabs[this.state.currentTab])
    // let percentage = 3
    // if (total.length > 6) {
    //   percentage = 7
    // } else if (total.length > 8) {
    //   percentage = 9
    // } else if (total.length > 10) {
    //   percentage = 11
    // } else if (total.length > 12) {
    //   percentage = 13
    // }  else if (total.length > 14) {
    //   percentage = 20
    // }
    // const width = total.length > 3 && ['soft', 'trait'].indexOf(this.state.currentTab) === -1 ? total.length * percentage + 100 : 100

    const width = 100
    
    return (
      <React.Fragment>
        <nav className="workZone__nav">
          {extraTabs}
          {tabs}
          <button
            id="addTabBtn"
            onClick={this.handleTabChange}
            className="workZone__nav__tab btn"
            onDragOver={this.handleTabChange}
          >
            +
          </button>
        </nav>
        <div className="workZone__body" style={{
          width: `${width}%`
        }}>
          {body}
        </div>
        <Modal 
          handler={this.handleModalResponse}
          options={this.state.modal.options} 
          show={this.state.modal.show} 
          text={this.state.modal.text} 
          key={this.state.modal.key} 
        />
      </React.Fragment>
    )
  }
}



class ExtraSkills extends MainZone {
  constructor(props) {
    super(props);

    this.drop = this.drop.bind(this)
    this.dragEnter = this.dragEnter.bind(this)
    this.dragLeave = this.dragLeave.bind(this)
    this.dragOver = this.dragOver.bind(this)
    this.dropAsSynonim = this.dropAsSynonim.bind(this)
    this.updateData = this.updateData.bind(this)
  }

  dragEnter(e) {
    const className = e.target.className
    const classArr = className.split(' ')

    if (classArr.indexOf('dragOver') === -1) {
      e.target.className = className + ' dragOver'
    }
  }

  dragLeave(e) {
    const className = e.target.className
    const classArr = className.split(' ')
    const index = classArr.indexOf('dragOver')

    if (index > -1) {
      classArr.splice(index, 1)
      e.target.className = classArr.join(' ')
    }
  }

  dragOver(ev){
    ev.preventDefault();
  }

  drop(ev) {
    ev.preventDefault();
    const key = ev.dataTransfer.getData("key");
    
    this.dropSkillBlock(key)
  }

  updateData(key) {
    if (key !== '' && key !== ' ') {
      const tabs = copyObject(this.props.tabs)
      const data = this.props.data
      const skills = data === undefined ? {} : data

      skills[key] = ""
      tabs[this.props.currentTab] = skills

      this.handleStateSet({
        tabs: tabs,
        raw: {
          ...this.props.raw,
          [key]: {
            ...this.props.raw[key],
            visible: false,
            type: this.props.currentTab
          }
        }
      })
    }
  }

  dropSkillBlock(key, parent = '') {
    if (key !== '' && key !== ' ') {
      this.updateData(key);
    }
  }

  dropAsSynonim(ev) {
    ev.preventDefault()

    const key = ev.dataTransfer.getData("key")
    let elem = ev.target
    let parentKey = elem.dataset.key

    if (parentKey === undefined){
      while(parentKey === undefined) {
        elem = elem.parentElement
        parentKey = elem.dataset.key
      }
    }
    
    const synonyms = this.props.raw[parentKey].synonyms || []
    synonyms.push(key)

    this.props.stateHandler({
      raw: {
        ...this.props.raw,
        [parentKey]: {
          ...this.props.raw[parentKey],
          synonyms: synonyms,
        },
        [key]: {
          ...this.props.raw[key],
          visible: false,
        }
      },
    })
  }

  buildEmptyElem(){
    return <div
      className="workZone__elem empty"
      onDrop={this.drop}
      onDragOver={this.dragOver}
      onDragEnter={this.dragEnter}
      onDragLeave={this.dragLeave}
      onClick={this.onEmptyElemClick}
      data-parent={this.props.currentTab}
    >
      <InnerInput
        id=""
        name=""
        raw={this.props.raw}
        tabs={this.props.tabs}
        currentTab={this.props.currentTab}
        stateHandler={this.handleStateSet}
        tabHandler={this.updateData}
        placeholder="Enter skill name"
      />
    </div>
  }

  buildSkill(key) {
    const skill = this.props.raw[key] || this.props.raw[key.replace(" ", "_")];
    const name = skill.name;
    const type = skill.type ? skill.type.slice(0, 2) : "?";
    const synonyms = skill.synonyms;
  
    const skillType = (
      <button
        data-key={key}
        onClick={this.handleSkillTypeClick}
        className="elemTypeBtn"
      >
        {type}
      </button>
    );

    const bgArr = ['#6B6EB2', '#9498E5', '#B8BBFB']
    
    return ( 
      <div
        className="workZone__elem"
        onDrop={this.dropAsSynonim}
        onDragOver={this.dragOver}
        data-key={key}
        style={{
          backgroundColor: bgArr[Math.floor(Math.random() * 2)]
        }}
      >
        <div className="workZone__elem__name">
          {name}
          {skillType}
        </div>
        {
          synonyms !== undefined && <Synonyms 
            keys={synonyms} 
            raw={this.props.raw} 
            parent={key}
            handler={this.handleStateSet}
          />
        }

        <button className="removeSkillBtn" data-key={key} onClick={this.handleRemoveSkillClick}>
          <FontAwesomeIcon icon={icon({name: 'eraser', style: 'solid'})} />
        </button>
      </div>
    );
  }

  render() {
    const data = this.props.data
    const skills = data === undefined ? {} : data

    return <React.Fragment>
      <div className="workZone__flex">
        {
          Object.keys(skills).map((key, i) => {
            return <React.Fragment key={key}>{this.buildSkill(key)}</React.Fragment>
          })
        }
        {this.buildEmptyElem()}
      </div>

      <Modal 
        handler={this.handleModalResponse}
        options={this.state.modal.options} 
        show={this.state.modal.show} 
        text={this.state.modal.text} 
        key={this.state.modal.key} 
      />
    </React.Fragment>
  }
}