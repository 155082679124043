import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function Expand({children}) {
  const [opened, setOpened] = useState(false)

  return <div className={`courseEdit__expand ${opened ? 'opened': ''}`}>
    <div className="courseEdit__btnBlock">
      <button className="expandBtn" onClick={() => { setOpened(!opened) }}>
        <FontAwesomeIcon icon={icon({ name: 'caret-down', style: 'solid' })} />
      </button>
    </div>
    {children}
  </div>
}