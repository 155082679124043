import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, signInWithCredential } from 'firebase/auth'
import { auth, authCourses } from '../firebase.js'
import logo from "../img/logo.svg"
import googleLogo from "../img/google.svg"

const Login = () => {
  const navigate = useNavigate()
  const handleSubmit = useCallback(async e => {
    e.preventDefault()

    const provider = new GoogleAuthProvider();
    try {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // const user = result.user;
          
          signInWithCredential(authCourses, credential)
            .then(() => {
              navigate('/home')
            })
            .catch((error) => {
              console.log(error)
            })
        })
      
    } catch (e) {
      alert(e.message)
    }
  }, [navigate])

  return (
    <section className="mainSection">
      <div className="leftBar">
        <div className="leftBar__head">
          <img src={logo} alt="Logo" className="leftBar__logo" />
          <span className="leftBar__version">v{process.env.REACT_APP_VERSION}</span>
        </div>
        <div className="leftBar__flex">
          <div className="leftBar__login">
            <h1>You must be logged in</h1>
            <form onSubmit={handleSubmit}>
              <button className="googleBtn" type="submit">
                <img src={googleLogo} alt="Google Logo" />
                Sign in via Google
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;