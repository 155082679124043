import React, { useState, useEffect } from 'react'
import String from './String'
import Dictionary from './Dictionary'
import Number from './Number'
import { normaliseStr } from '../../components/normaliseStr.js'
import { dataType } from '../../components/dataType.js'

export default function List({ data, dataKey, dataHandler }) {
  const [value, setValue] = useState([])
  const reindexList = arr => arr.filter(val => val)
  const normaliseList = (arr) => {
    return arr.map((el) => {
      return typeof el === 'string' ? normaliseStr(el) : el
    })
  }

  const updateData = (id, val) => {
    let mutatedList = value.map(el => el)
    mutatedList[id] = val
    if (val === '' || val === null) delete mutatedList[id];
    
    dataHandler(dataKey, reindexList(mutatedList))
  }

  useEffect(() => {
    setValue(normaliseList(data))
  }, [data])

  return <div className="courseEdit__list">{value.map((elem, i) => {
    return <React.Fragment key={i}>
      {dataType(elem, 'string') && <String data={elem} dataKey={i} dataHandler={updateData} />}
      {dataType(elem, 'number') && <Number data={elem} dataKey={i} dataHandler={updateData} />}
      {dataType(elem, 'array') && <List data={elem} dataKey={i} dataHandler={updateData} />}
      {dataType(elem, 'object') && <Dictionary data={elem} dataKey={i} dataHandler={updateData} />}
    </React.Fragment>
  })}</div>
}