import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import { getSandbox } from '../../db/professionsSandbox.js'



export default function ProfessionDashboard(){
  const navigate = useNavigate()
  const { profession } = useParams()
  const [title, setTitle] = useState("")
  const [status, setStatus] = useState("")
  const [words, setWords] = useState(0)

  const StatusIcon = ({ status }) => {
    return <>
      {status === undefined && <i className="fi fi-br-hourglass-start blue"></i>}
      {status === 'merged' && <i className="fi fi-br-code-merge yellow"></i>}
      {status === 'done' && <i className="fi fi-br-badge-check green"></i>}
    </>
  }

  const Words = ({ words }) => {
    return <div className="mainZone__infoBlock">
      <div className="mainZone__infoBlock__icon">
        <i className="fi fi-rr-note"></i>
      </div>
      <div className="mainZone__infoBlock__text">
        <span><strong>{words}</strong> words to analyse</span>
      </div>
    </div>
  }

  const EditBtn = () => {
    return <button className="mainZone__infoBtn" onClick={() => { navigate('edit') }}>
      <i className="fi fi-rr-blog-pencil"></i>
      <span>Edit</span>
    </button>
  }

  const calcWords = (raw) => {
    return Object.keys(raw).filter(key => raw[key].visible !== false).length
  }

  useEffect(() => {
    const fetchSandbox = async (profession) => {
      const sandbox = await getSandbox(profession)
      
      setTitle(sandbox.name)
      setStatus(sandbox.status)
      setWords(calcWords(sandbox.raw))
    }
    
    fetchSandbox(profession)
  }, [profession])

  return <div className="mainZone__block">
    <h3>{title}<StatusIcon status={status} /></h3>
    <div className="mainZone__flex">
      <Words words={words} />
      <EditBtn />
    </div>
  </div>
}