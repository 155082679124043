import React from 'react'

export default class ViewTabs extends React.Component {
  constructor(props) {
    super(props)

    this.handleTabDragOver = this.handleTabDragOver.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleStateSet = this.handleStateSet.bind(this);
    this.changeView = this.changeView.bind(this);
    this.buildHTML = this.buildHTML.bind(this);
  }

  handleStateSet(data) {
    this.props.stateHandler(data)
  }

  handleViewChange(event) {
    this.handleStateSet({ view: event.target.value })
  }

  changeView(value) {
    this.handleStateSet({ view: value })
  }

  handleTabDragOver(e) {
    const value = e.target.dataset.tab_value;

    if (value !== this.props.view) {
      this.changeView(value);
    }
  }

  buildHTML() {
    const view = this.props.view;
    const content = ["main", "trash", "specialisations"].map((key, i) => {
      const checked = key === view;
      return (
        <React.Fragment key={i}>
          <input
            type="radio"
            name="viewTab"
            value={key}
            checked={checked}
            id={`${key}_viewTab`}
            onChange={this.handleViewChange}
          ></input>
          <label
            htmlFor={`${key}_viewTab`}
            onDragOver={this.handleTabDragOver}
            data-tab_value={key}
          >
            {key}
          </label>
        </React.Fragment>
      );
    });

    return <div className="workZone__viewTabs">{content}</div>;
  }

  render(){
    return (this.buildHTML())
  }
}