import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { upload } from '../../storage/coursesImages.js'

export default function ImageUpload ({handler}) {
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  const progressHandler = (n) => {
    setPercent(Math.ceil(Number(n)))
  }

  const urlHandler = (url) => {
    setFile("")
    setPercent(0)
    handler(url)
    setIsLoading(false)
  }

  const handleChange = (e) => { 
    if (e.target.files[0]) {
      setFile(e.target.files[0]); 
    }
  }

  const handleUpload = () => { 
    if (file !== '' && file.name !== undefined) {
      setIsLoading(true)
      upload(file, progressHandler, urlHandler); 
    }
  }

  useEffect(() => {
    setFile("")
    setPercent(0)
    setIsLoading(false)
  }, [])

  return (
    <div className="fileInput">
      <input type="file" id="file" onChange={handleChange} accept="/image/*" />
      <label htmlFor="file">
        <span>Select file</span>
        {file !== "" && <p className="fileInput__name">{file.name}</p>}
        {isLoading && <div className="fileInput__progress" style={{width: `${percent}%`}}></div>}
      </label>
      <button className="fileInput__btn" onClick={handleUpload} disabled={file === "" || isLoading}>
        <FontAwesomeIcon icon={icon({ name: 'cloud-arrow-up', style: 'solid' })} />
      </button>
    </div>
  );
}