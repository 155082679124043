import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Modal from './modal.js'
import {mergeSkills} from '../../db/skills.js' 
import {updateProfessionStatus} from '../../db/professionsSandbox' 
import {getAllKeys} from '../../components/objectFunctions.js' 
import {copyObject} from '../../components/copyObject.js' 

export default function MergeButton(props){
  const navigate = useNavigate();

  return <Merge {...props} navigate={navigate} />
}

class Merge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'ready',
      reload: false,
      modal: {
        show: false,
        text: "",
        key: "",
        options: []
      }
    }

    this.reload = this.reload.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleModalResponse = this.handleModalResponse.bind(this)
  }

  reload() {
    setTimeout(() => {
      this.props.navigate('../')
    }, 2000);
  }

  isKeyValid(key){
    return !/[`.#$\/\[\]]/.test(key) && key !== ''
  }

  showModal(){
    this.setState({
      modal: {
        show: true,
        text: "Do you want to finish this edit and merge data with main skills data?",
        key: "",
        options: ['mergeData']
      }
    })
  }

  loopObject(obj, parent, group, result) {
    
    for (let key in obj) {
      result[key] = {
        group: ['soft', 'trait'].indexOf(group) === -1 ? group : ""
      }
      
      if (['soft', 'trait'].indexOf(parent) === -1) {
        const parentType = this.props.raw[parent].parentType
        if (parentType !== undefined && parentType === 'class') result[key]['class-parent'] = parent
        if (parentType === undefined || parentType === 'general') result[key]['parent'] = parent
      }
      
      if (typeof obj[key] === "object") {
        result = this.loopObject(obj[key], key, group, result)
      }
    }

    return result
  }

  prepareSkillsData(){
    const tabs = copyObject(this.props.tabs)
    let allKeys = {}

    for (let key in tabs) {
      if (this.isKeyValid(key)){
        allKeys[key] = {group: ['soft', 'trait'].indexOf(key) === -1 ? key : ""}
        allKeys = this.loopObject(tabs[key], key, key, allKeys)
      }
    }

    ['soft', 'trait'].forEach(key => {
      if (allKeys[key] !== undefined) {
        delete allKeys[key]
      }
    })

    const raw = copyObject(this.props.raw)
    for (let key in raw) {
      if (raw[key].visible === true) {
        allKeys[key] = {group: ""}
      }
    }

    return allKeys
  }

  isProfessionExist(arr, profession){
    let result = false

    if (Array.isArray(arr)){
      arr.forEach(data => {
        if (data.name === profession) {
          result = true
        }
      })
    }

    if (typeof arr === 'object' && !Array.isArray(arr)){
      Object.values(arr).forEach(data => {
        if (data.name === profession) {
          result = true
        }
      })
    }
    

    return result
  }

  getProfessionIndex(arr, profession) {
    let index = false
    
    if (Array.isArray(arr)){
      arr.forEach((data, i) => {
        if (data.name === profession) {
          index = i
        }
      })
    }

    if (typeof arr === 'object' && !Array.isArray(arr)){
      Object.keys(arr).forEach(key => {
        if (arr[key].name === profession) {
          index = key
        }
      })
    }

    return index
  }

  getEditedSkills(){
    const tabsKeys = getAllKeys(this.props.tabs)
    const stopsKeys = getAllKeys(this.props.stops)
    const deletedKeys = getAllKeys(this.props.deleted)
    const specialisationsKeys = getAllKeys(this.props.specialisations)
    const professionSynonymsKeys = getAllKeys(this.props.profession_synonyms)

    const allKeys = tabsKeys.concat(stopsKeys, deletedKeys, specialisationsKeys, professionSynonymsKeys)

    return allKeys
  }

  updateSkills(data){
    const tabs = copyObject(this.props.tabs)
    const raw = copyObject(this.props.raw)
    const skills = copyObject(this.props.skills)
    const profession = this.props.profession.replace(/_v\d+/g, '')

    for (let key in data) {
      if (this.isKeyValid(key)) {
        const name = raw[key]["name"]
        // const skill_id = data[key].skill_id
        const type = raw[key]["type"] || ""
        const group = data[key]["group"] || ""
        const parent = data[key]["parent"] || ""
        const score = raw[key]["score"] || 0
        const synonyms = raw[key]["synonyms"] || ""
        const classParent = data[key]["class-parent"] || ""
        const job_ids = raw[key].job_ids !== undefined ? raw[key]["job_ids"].filter((key, i, arr) => {
          return arr.indexOf(key) === i
        }) : []
        const isSkill = key in tabs === false

        // Lower key to prevent conflict in skills
        key = key.toLowerCase()
        const professions_names = skills[key]?.professions_names || []
        professions_names.push(profession)
        
        
        if (skills[key] === undefined) skills[key] = {}
        if (skills[key]["professions"] === undefined) skills[key]["professions"] = {}
        

        skills[key]["name"] = name === undefined ? key.replaceAll("_", " ") : name
        skills[key]["synonyms"] = Array.isArray(skills[key]["synonyms"]) ? skills[key]["synonyms"].concat(synonyms) : synonyms
        skills[key]["type"] = type || ''
        skills[key]["score"] = skills[key]["score"] || score
        skills[key]["professions_names"] = professions_names
        skills[key]["professions"][profession] = {
          "name": profession,
          "group": group,
          "parent": parent,
          "class-parent": classParent,
          "synonyms": synonyms,
          "score": skills[key]["score"] !== undefined ? skills[key]["score"] : 0,
          "job_ids": job_ids
        }
        skills[key]["isSkill"] = isSkill
      }      
    }

    return skills
  }

  prepareUngrouppedSkills(){
    const editedSkills = this.getEditedSkills()
    const data = {}

    Object.keys(this.props.raw).forEach(key => {
      if (editedSkills.indexOf(key) === -1 && this.props.raw[key].visible !== false) {
        data[key] = {}
      }
    })

    return data
  }

  getUpdatedSkills(){
    const skillsData = this.prepareSkillsData()
    const ungrouppedSkills = this.prepareUngrouppedSkills()
    const dataToUpdate = {
      ...skillsData,
      ...ungrouppedSkills
    }

    const updatedSkills = this.updateSkills(dataToUpdate)

    return updatedSkills
  }

  handleModalResponse(response) {
    const profession = this.props.profession

    if (response === 'mergeData') {
      const updatedData = this.getUpdatedSkills()

      this.setState({status: 'load'})
      
      mergeSkills(updatedData, profession, (error, res) => {
        if (!error) {
          updateProfessionStatus(profession, 'merged', (error, res) => {
            if (!error) {
              this.showResponse('saved')
              this.reload()
            } else {
              console.log(error)
              this.showResponse('error')
            }
          })
        } else {
          console.log(error)
          this.showResponse('error')
        }
      })
    }

    this.setState({
      modal: {
        text: "",
        show: false,
        key: "",
        options: []
      }
    })
  }

  showResponse(res){
    this.setState({status: res})

    setTimeout(() => {
      this.setState({status: 'ready'})
    }, 3000)
  }

  handleClick(e){
    this.showModal()
  }

  render(){
    let btnIcon = ''
    let btnText = ''
    let btnDisabled = ''

    switch (this.state.status){
      case 'ready':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'flag-checkered', style: 'solid'})} />
        btnText = 'finish'
        btnDisabled = false
        break;

      case 'load':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'spinner', style: 'solid'})} spin />
        btnText = 'merging'
        btnDisabled = true
        break;

      case 'error':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'times', style: 'solid'})} />
        btnText = 'error'
        btnDisabled = true
        break;

      case 'saved':
        btnIcon = <FontAwesomeIcon icon={icon({name: 'check', style: 'solid'})} />
        btnText = 'saved'
        btnDisabled = true
        break;

      default:
        break;
    }

    return <React.Fragment>
      <button className={`actionBtn ${this.state.status}`} style={{'--level': this.props.level}} onClick={this.handleClick} disabled={btnDisabled}>
        {btnIcon}
        {btnText}
      </button>
      <Modal 
        handler={this.handleModalResponse}
        options={this.state.modal.options} 
        show={this.state.modal.show} 
        text={this.state.modal.text} 
        key={this.state.modal.key} 
      />
    </React.Fragment>
  }
}