import React from "react"

export default class TrashZone extends React.Component {
  constructor(props) {
    super(props)

    this.handleStateSet = this.handleStateSet.bind(this)
    this.hideSkillBlock = this.hideSkillBlock.bind(this)
    
    this.buildHTML = this.buildHTML.bind(this)
    this.buildDeletedSkills = this.buildDeletedSkills.bind(this)
    this.replaceSkill = this.replaceSkill.bind(this);

    this.getZone = this.getZone.bind(this);
    this.drop = this.drop.bind(this);
    this.drag = this.drag.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.dragEnter = this.dragEnter.bind(this)
    this.dragLeave = this.dragLeave.bind(this)
  }

  replaceSkill(key, place) {
    this.handleStateSet({
      [place]: {
        ...this.props[place],
        [key]: this.props.raw[key]["name"] || key.replaceAll("_", " "),
      },
    })
  }

  getZone(elem) {
    let zone = elem.dataset.zone

    if (zone === undefined){
      while(zone === undefined) {
        elem = elem.parentElement
        zone = elem.dataset.zone
      }
    }

    return zone
  }

  drop(e) {
    const key = e.dataTransfer.getData("key");
    const parent = e.dataTransfer.getData("parent");
    const zone = this.getZone(e.target)

    this.replaceSkill(key, zone)
    this.hideSkillBlock(key)
    this.removeFromPreviousZone(key, parent, zone)
  }

  removeFromPreviousZone(key, previous, next){
    if (previous !== '' && previous !== undefined && previous !== next) {
      const copiedState = this.props[previous]

      delete copiedState[key]

      this.handleStateSet({[previous]: copiedState})
    }
  }

  drag(e) {
    const key = e.target['id']
    const parent = e.target.dataset.parent
    
    e.dataTransfer.setData("parent", parent);
    e.dataTransfer.setData("key", key);
  }

  dragEnd(e) {
    e.preventDefault();
  }

  dragOver(e) {
    e.preventDefault();
  }

  dragEnter(e) {
    this.addDropOverClass(e.target)
  }

  dragLeave(e) {
    this.removeDropOverClass(e.target)
  }

  hasDropOverClass(elem){
    return elem.className.split(' ').indexOf('dragOver') > -1
  }

  addDropOverClass(elem) {
    const className = elem.className
    const classArr = className.split(' ')

    if (classArr.indexOf('dragOver') === -1) {
      elem.className = className + ' dragOver'
    }
  }

  removeDropOverClass(elem){
    const className = elem.className
    const classArr = className.split(' ')
    const index = classArr.indexOf('dragOver')

    if (index > -1) {
      classArr.splice(index, 1)
      elem.className = classArr.join(' ')
    }
  }

  handleStateSet(data) {
    this.props.stateHandler(data)
  }

  hideSkillBlock(key){
    this.props.skillDataHandler(key, 'visible', false)
  }

  removeFromTrash(key, parent){
    const data = this.props[parent]

    if (data !== undefined && data[key] !== undefined) {
      delete data[key]

      this.handleStateSet({
        [parent]: data
      })
    }
  }

  buildDeletedSkills(){
    return Object.keys(this.props.deleted).map((key) => {
      const data = this.props.raw[key];
      const name = data?.name ? data.name : key
      const colorCss = data?.color ? data.color : "";
      const blockClass = `skillBlock ${colorCss}`;

      return (
        <div
          className={blockClass}
          key={key}
          id={key}
          draggable="true"
          onDragStart={this.drag}
          data-parent="deleted"
        >
          {name}
        </div>
      );
    });
  }

  buildStopsSkills(){
    return Object.keys(this.props.stops).map((key) => {
      
      const data = this.props.raw[key];
      const name = data ? data.name : key
      const colorCss = data ? data.color : "";
      const blockClass = `skillBlock ${colorCss}`;

      return (
        <div
          className={blockClass}
          key={key}
          id={key}
          draggable="true"
          onDragStart={this.drag}
          onDragEnd={this.dragEnd}
          data-parent="stops"
        >
          {name}
        </div>
      );
    });
  }

  buildHTML() {
    return (
      <React.Fragment>
        <div className="workZone__trash__container">
          <h3>Stops</h3>
          <div
            className="workZone__trash__block"
            onDrop={this.drop}
            onDragOver={this.dragOver}
            // onDragEnter={this.dragEnter}
            // onDragLeave={this.dragLeave}
            data-zone="stops"
          >
            <div className="workZone__trash__box">
              {this.buildStopsSkills()}
            </div>
          </div>
        </div>
        <div className="workZone__trash__container">
          <h3>Just delete</h3>
          <div
            className="workZone__trash__block"
            onDrop={this.drop}
            onDragOver={this.dragOver}
            // onDragEnter={this.dragEnter}
            // onDragLeave={this.dragLeave}
            data-zone="deleted"
          >
            <div className="workZone__trash__box">
              {this.buildDeletedSkills()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (<div className="workZone__trash">
      {this.buildHTML()}
    </div>)
  }
}