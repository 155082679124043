import React from 'react';
import { UserAuth } from '../context/AuthContext';

const Home = () => {
  const { user } = UserAuth();

  return (user.isAdmin ? <div>
    <h1>The dashboard will be here</h1>
  </div> : <></>)
}

export default Home