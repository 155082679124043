import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getAllKeys, findPath, updateObject, deleteKey } from '../../components/objectFunctions.js'
import {getFirestoreSkills, getStops} from "../../db/skills.js"
import logo from "../../img/logo.svg"
import TrashZone from "./trash.js"
import SpecialisationsZone from "./specialisations.js"
import MainZone from "./main.js"
import LeftBar from './leftBar.js';
import ViewTabs from './viewTabs.js';
import Autosave from './autosave.js';
import SaveButton from './saveButton.js';
import MergeButton from './mergeButton.js';
import Reload from '../../components/Reload'
import AddSkill from './addSkill.js'
import Loading from './loading.js'
import {buildProfessionTree} from '../../components/buildProfessionTree.js'
import {buildGlobalTree} from '../../components/buildGlobalTree.js'
import {copyObject} from '../../components/copyObject.js'


export default class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'name',
      order: 'ASC',
      editReady: false,
      profession: this.props.profession,
      name: this.props.sandbox.name,
      parent_career: this.props.sandbox.parent_career,
      raw: this.props.sandbox.raw,
      tabs: this.props.sandbox.tabs,
      deleted: this.props.sandbox.deleted,
      stops: this.props.sandbox.stops,
      specialisations: this.props.sandbox.specialisations || {},
      profession_synonyms: this.props.sandbox.profession_synonyms || {},
      skills: {},
      currentTab: null,
      view: "main",
      containedPhrase: null
    };

    this.prepareSandbox = this.prepareSandbox.bind(this);
    this.addSkillHandler = this.addSkillHandler.bind(this);
    this.setSynonymHandler = this.setSynonymHandler.bind(this);
    this.updateSandbox = this.updateSandbox.bind(this);
    this.skillDataHandler = this.skillDataHandler.bind(this);
    this.stateHandler = this.stateHandler.bind(this)
    this.handleTypeClick = this.handleTypeClick.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
  }
  
  stateHandler(data){
    this.setState(data)
  }

  skillDataHandler(skill, key, value){
    if (skill !== '' && key !== ''){
      this.setState({
        raw: {
          ...this.state.raw,
          [skill]: {
            ...this.state.raw[skill],
            [key]: value,
          },
        },
      });
    }
  }

  handleTypeClick(event) {
    this.changeSkillType(event.target.getAttribute("data-key"));
    this.animateTypeButton(event.target);
  }

  changeSkillType(key) {
    const types = ["hard", "soft", "tech", "trait", "language"];
    const type = this.state.raw[key].type;
    const new_type = types[types.indexOf(type) + 1] || types[0];

    this.setState({
      raw: {
        ...this.state.raw,
        [key]: {
          ...this.state.raw[key],
          type: new_type,
        },
      },
    });
  }

  animateTypeButton(btn) {
    const className = "elemTypeBtn";

    btn.className = `${className} animated`;
    setTimeout(() => {
      btn.className = `${className}`;
    }, 200);
  }

  isAlertExist(skillObj, type) {
    if (skillObj) {
      const data = copyObject(skillObj)

      if (data !== undefined) {
        if (data.alert !== undefined && Array.isArray(data.alert.nodes)) {
          return data.alert.nodes.filter(node => node.type === type).length === 1
        }
      }
    }

    return false
  }

  isAlertSolved(data, type){
    if (data !== undefined) {
      if (data.alert !== undefined && Array.isArray(data.alert.nodes)) {
        let result = false

        data.alert.nodes.forEach(node => {
          if (node["type"] === type) {
            result = node["solved"] === true
          }
        })

        return result
      }
    }

    return false
  }

  createAlert(type) {
    return {
      nodes: [
        {
          type: type,
          options: []
        }
      ]
    }
  }

  alertAddOption(obj, type, option){
    if (obj) {
      const data = copyObject(obj)

      if (Array.isArray(data.nodes)) {
        data.nodes.forEach((node, i) => {
          if (node.type === type) {
            if (Array.isArray(node.options)) {
              data.nodes[i].options.push(option)
            } else {
              data.nodes[i].options = [option]
            }
          }
        })
      }

      return data
    }
    
    return {}
  }

  getParentList(key, skills_relations) {
    let parentList = []
    const result = []

    if (skills_relations[key]) {
      if (Array.isArray(skills_relations[key].parents)) {
        parentList = skills_relations[key].parents
      } else if (typeof skills_relations[key].parent === "string") {
        parentList = [skills_relations[key].parent]
      }
    }

    parentList.forEach(parent => {
      result.push(parent)

      // If this is global tree
      if (Array.isArray(skills_relations[key]["parents"])) {
        if (skills_relations[parent] !== undefined && Array.isArray(skills_relations[parent].synonyms)) {
          skills_relations[parent].synonyms.forEach(synonym => {
            result.push(synonym)
          })
        }
      }
    })

    return result
  }

  isSynonymOfParentProfession(key, skill_data) {
    const searchString = key.replaceAll('_', ' ').replaceAll('-', ' ')
    const parentProfession = this.state.parent_career
    const synonyms = skill_data?.professions?.[parentProfession]?.synonyms || []

    if (synonyms.includes(searchString)) {
      return true
    }

    return false
  }

  prepareSandbox(skills, stopsDB, skills_relations) {
    const sandbox = copyObject(this.state.raw)
    const editedSkills = this.getEditedSkills()
    let synonyms_found = 0
    // const deletedObj = Object.assign({}, this.state.deleted)

    Object.keys(skills).forEach((k) => {
      const data = skills[k];
      const name = data.name;
      const type = data.type
      const synonyms = data.synonyms || [];
      let parentsList = this.getParentList(k, skills_relations)
      
      let skillExist = false;

      // Check if skill exist in main DB
      for (let i in sandbox) {
        if (sandbox[i].name === name) {
          sandbox[i].color = "green";
          sandbox[i].isExist = true;
          sandbox[i].type = type

          // Add synonyms if they exist
          if (synonyms !== undefined) {
            if (sandbox[i].synonyms === undefined) {
              sandbox[i].synonyms = synonyms
            } else {
              let list = sandbox[i].synonyms
              list = Array.isArray(list) ? list : []
              list = list.concat(synonyms)
              list.filter((k,i) => list.indexOf(k) === i)

              sandbox[i].synonyms = list
            }
          }
          
          skillExist = true;
          break;
        }
      }

      // Filter for synonyms
      if (synonyms !== undefined && synonyms.length > 0) {
        for (let i in sandbox) {
          if (
            synonyms.includes(i) || 
            synonyms.includes(i.replaceAll(' ','_')) ||
            synonyms.includes(i.replaceAll('_',' '))
          ) {
            if (!skillExist) {
              // If this skill is a synonym of parent profession
              if (sandbox[k] && this.isSynonymOfParentProfession(i, data)) {
                sandbox[i].isSynonym = true
                editedSkills.push(i)
                
                // Add this skill as synonym to parent skill
                if (Array.isArray(sandbox[k]?.synonyms)) {
                  sandbox[k].synonyms.push(i)
                } else {
                  sandbox[k].synonyms = [i]
                }

                // Increase number of found synonyms
                synonyms_found += 1
              } else {
                if (!this.isAlertExist(sandbox[i], 'synonym') && i !== k) {
                  sandbox[i].alert = this.createAlert('synonym')
                }
                
                if (!this.isAlertSolved(sandbox[i], 'synonym') && i !== k) {
                  sandbox[i].alert = this.alertAddOption(sandbox[i].alert, 'synonym', k)
                }
                
                sandbox[i].visible = true
              }
            }
          }
        }
      }

      // Build parent-child relations with existing skills
      if (skillExist === true) {
        for (let i in sandbox) {
          const item = sandbox[i];

          if (
            parentsList.includes(item.name) || parentsList.includes(i) || 
            (Array.isArray(item.synonyms) && item.synonyms.some(synonym => parentsList.includes(synonym)))
          ) {
            if (sandbox[k] && k !== i) sandbox[k]["general-parent"] = i

            if (!Array.isArray(sandbox[i].children)){
              sandbox[i].children = []
            }

            if (!sandbox[i].children.includes(k)) sandbox[i].children.push(k);
            sandbox[i].children = sandbox[i].children.concat(synonyms)
          }
        }
      }
    });

    Object.keys(sandbox).forEach(key => {
      if (sandbox[key].visible === false && editedSkills.indexOf(key) === -1) {
        sandbox[key].visible = true
      }

      if (sandbox[key].visible === false && sandbox[key].isSynonym === true) {
        sandbox[key].visible = true
      }

      if (sandbox[key].synonyms !== undefined && sandbox[key].synonyms.length > 1) {
        sandbox[key].synonyms = sandbox[key].synonyms.filter((k, i, a) => a.indexOf(k) === i)
      }

      if (sandbox[key].name === undefined) {
        sandbox[key]["name"] = key.replaceAll('_', ' ')
      }

      if (editedSkills.includes(key)) {
        sandbox[key].visible = false
      }
    })

    // Hide synonyms
    for (let i in sandbox) {
      const item_synonyms = sandbox[i].synonyms

      if (item_synonyms !== undefined && item_synonyms.length > 0) {
        for(let y of item_synonyms) {
          y = y.replaceAll(' ', '_').replaceAll('-', '_');
          
          if (sandbox[y] !== undefined) {
            // This skill is already set as synonym of EDITED skill (in tabs, deleted, and etc.)
            if (editedSkills.indexOf(i) >= 0) {
              sandbox[y].visible = false

              if (sandbox[y].alert !== undefined) {
                delete sandbox[y].alert
              }
            } else {
              if (sandbox[y].isSynonym !== true) {
                if (!this.isAlertExist(sandbox[y], 'synonym') && y !== i) {
                  sandbox[y].alert = this.createAlert('synonym')
                } 

                if (!this.isAlertSolved(sandbox[y], 'synonym') && y !== i) {
                  sandbox[y].alert = this.alertAddOption(sandbox[y].alert, 'synonym', i)
                }
              } else {
                sandbox[y].visible = false
              }
            }
          }
        }
      }
    }

    // Correct tabs for wrong data
    
    const tabs = this.correctTabs()
    Object.keys(sandbox).forEach(key => {
      if (['soft', 'trait'].includes(sandbox[key]['type'])) {
        if ((this.isAlertExist(sandbox[key], 'synonym') && this.isAlertSolved(sandbox[key], 'synonym')) || !this.isAlertExist(sandbox[key], 'synonym')) {
          sandbox[key].visible = false
          tabs[sandbox[key].type][key] = ""
        }
      }
    })

    // Move stop to stops
    const stops = Object.assign({}, this.state.stops)
    Object.keys(stopsDB).forEach(key => {
      if (sandbox[key] !== undefined && stops[key] === undefined) {
        sandbox[key].visible = false
        stops[key] = ""
      }
    })

    // Delete job_ids from raw skills
    Object.keys(sandbox).forEach(key => {
      if (sandbox[key]['job_ids'] !== undefined && Array.isArray(sandbox[key]['job_ids'])) {
        delete sandbox[key]['job_ids']
      }
    })

    this.setState({
      skills: skills,
      skills_relations: skills_relations,
      tabs: tabs,
      raw: sandbox,
      stops: stops,
      editReady: true
    });

    console.log(`Synonyms were found: ${synonyms_found}`)
  }

  addSkillHandler(newSkill, newRaw){
    this.updateSandbox(newSkill, newRaw)
  }

  setSynonymHandler(keys, newRaw){
    this.updateSandbox(keys, newRaw)
  }

  updateSandbox(newSkill, newRaw){
    const dataToProceed = {}
    // const tabs = copyObject(this.state.tabs)
    const sandbox = copyObject(newRaw)
    const skills = copyObject(this.state.skills)

    if (typeof newSkill === 'object' && !Array.isArray(newSkill) && newSkill !== null) {
      dataToProceed[Object.keys(newSkill)[0]] = newSkill[Object.keys(newSkill)[0]]
    } else if (Array.isArray(newSkill)) {
      newSkill.forEach(key => {
        dataToProceed[key] = sandbox[key]
      })
    }


    Object.keys(skills).forEach(skillKey => {
      // const skillKey = key
      const data = skills[skillKey];
      const name = data.name;
      const type = data.type
      let synonyms = data.synonyms || [];
      synonyms = synonyms.filter((k,i,arr) => arr.indexOf(k) === i && k !== skillKey)
      let parentsList = this.getParentList(skillKey, this.state.skills_relations)
      let skillExist = false;

      // Check if skill exist in main DB
      for (let i in sandbox) {
        if (sandbox[i].name === name) {
          sandbox[i].color = "green";
          sandbox[i].isExist = true;
          sandbox[i].type = type

          // Add synonyms if they exist
          if (synonyms !== undefined) {
            if (sandbox[i].synonyms === undefined) {
              sandbox[i].synonyms = synonyms
            } else {
              let list = sandbox[i].synonyms
              list = Array.isArray(list) ? list : []
              list = list.concat(synonyms)
              list.filter((k,i) => list.indexOf(k) === i)

              sandbox[i].synonyms = list
            }
          }
          
          skillExist = true;
          break;
        }
      }

      // Filter for synonyms
      if (synonyms !== undefined && synonyms.length > 0) {
        for (let i in sandbox) {
          // const item = sandbox[i];
          if (
            synonyms.includes(i) || 
            synonyms.includes(i.replaceAll(' ','_')) ||
            synonyms.includes(i.replaceAll('_',' '))
          ) {
            if (!skillExist) {
              if (!this.isAlertExist(sandbox[i], 'synonym') && i !== skillKey) {
                sandbox[i].alert = this.createAlert('synonym')
              }

              if (!this.isAlertSolved(sandbox[i], 'synonym') && i !== skillKey) {
                sandbox[i].alert = this.alertAddOption(sandbox[i].alert, 'synonym', skillKey)
              }

              sandbox[i].visible = true
            }
          }
        }
      }

      // Build parent-child relations with existing skills
      if (skillExist === true) {
        for (let i in sandbox) {
          const item = sandbox[i];
          
          if (
            parentsList.includes(item.name) || parentsList.includes(i) || 
            (Array.isArray(item.synonyms) && item.synonyms.some(synonym => parentsList.includes(synonym)))
          ) {
            if (sandbox[skillKey] && skillKey !== i) sandbox[skillKey]["general-parent"] = i;
            
            if (!Array.isArray(sandbox[i].children)){
              sandbox[i].children = []
            }
            

            if (!sandbox[i].children.includes(skillKey)) sandbox[i].children.push(skillKey);
            sandbox[i].children = sandbox[i].children.concat(synonyms)
          }
        }
      }
    })

    // const skillKey = Object.keys(newSkill)[0]
    const editedSkills = this.getEditedSkills()
    Object.keys(sandbox).forEach(key => {
      if (sandbox[key].visible === false && editedSkills.indexOf(key) === -1) {
        sandbox[key].visible = true
      }

      if (sandbox[key].visible === false && sandbox[key].isSynonym === true) {
        sandbox[key].visible = true
      }

      if (sandbox[key].synonyms !== undefined && sandbox[key].synonyms.length > 1) {
        sandbox[key].synonyms = sandbox[key].synonyms.filter((k, i, a) => a.indexOf(k) === i)
      }

      if (sandbox[key].name === undefined) {
        sandbox[key].name = key.replaceAll('_', ' ')
      }

      if (editedSkills.includes(key)) {
        sandbox[key].visible = false
      }
    })

    // Hide synonyms
    for (let i in sandbox) {
      const item_synonyms = sandbox[i].synonyms

      if (item_synonyms !== undefined && item_synonyms.length > 0) {
        for(let y of item_synonyms) {
          y = y.replaceAll(' ', '_').replaceAll('-', '_');
          
          if (sandbox[y] !== undefined) {
            
            // This skill is already set as synonym of EDITED skill (in tabs, deleted, and etc.)
            if (editedSkills.indexOf(i) >= 0) {
              sandbox[y].visible = false

              if (sandbox[y].alert !== undefined) {
                delete sandbox[y].alert
              }
            } else {
              if (!sandbox[y].isSynonym) {
                if (!this.isAlertExist(sandbox[y], 'synonym') && y !== i) {
                  sandbox[y].alert = this.createAlert('synonym')
                }

                if (!this.isAlertSolved(sandbox[y], 'synonym') && y !== i) {
                  sandbox[y].alert = this.alertAddOption(sandbox[y].alert, 'synonym', i)
                }
                
                if (!editedSkills.includes(y)) {
                  sandbox[y].visible = true
                }
              } else {
                sandbox[y].visible = false
              }
            }
          }
        }
      }
    }

    // Correct tabs for wrong data
    const tabs = this.correctTabs()
    
    Object.keys(sandbox).forEach(key => {
      if (['soft', 'trait'].indexOf(sandbox[key].type) >= 0) {
        if ((this.isAlertExist(sandbox[key], 'synonym') && this.isAlertSolved(sandbox[key], 'synonym')) || !this.isAlertExist(sandbox[key], 'synonym')) {
          sandbox[key].visible = false
          tabs[sandbox[key].type][key] = ""
        }
      }
    })

    this.setState({
      tabs: tabs,
      raw: sandbox,
    });
  }

  correctTabs(){
    const raw = copyObject(this.state.raw)
    let tabs = copyObject(this.state.tabs)
    const tabsKeys = getAllKeys(tabs)

    tabsKeys.forEach(key => {
      if (raw[key] === undefined && ['soft', 'trait'].indexOf(key) === -1) {
        let isChanged = false

        for (let i in raw) {
          const synonyms = raw[i].synonyms

          if (synonyms !== undefined && Array.isArray(synonyms) && synonyms.indexOf(key) !== -1) {
            const keyToChange = i
            const path = findPath(tabs, key)
            const fullPath = path.concat([key])
            const newPath = path.concat([keyToChange])

            let children = copyObject(tabs)
            fullPath.forEach(k => {children = children[k]})

            let newTabs = {}

            if (Object.keys(children).length > 0){ 
              for (let k in children) {
                newTabs = updateObject(tabs, newPath, k, children[k]);
              }

              newTabs = deleteKey(newTabs, fullPath)
            } else {
              newTabs = updateObject(tabs, path, keyToChange, "")
              newTabs = deleteKey(newTabs, fullPath)
            }

            tabs = newTabs
            isChanged = true
          }
        }

        if (isChanged === false) {
          const path = findPath(tabs, key)
          const fullPath = path.concat([key])

          let children = copyObject(tabs)
          fullPath.forEach(k => {children = children[k]})

          let newTabs = {}

          if (Object.keys(children).length > 0){ 
            for (let k in children) {
              newTabs = updateObject(tabs, path, k, children[k]);
            }

            newTabs = deleteKey(newTabs, fullPath)
          } else {
            newTabs = updateObject(tabs, path, key, "")
            newTabs = deleteKey(newTabs, fullPath)
          }

          tabs = newTabs
          isChanged = true
        }
      }
    })

    if (tabs.soft === undefined) {
      tabs.soft = {}
    }

    if (tabs.trait === undefined) {
      tabs.trait = {}
    }
    
    return tabs
  }

  totalSkills(){
    return Object.keys(this.state.raw).filter(key => {
      return this.state.raw[key].visible === undefined || this.state.raw[key].visible === true
    }).length
  }

  onSortChange(e){
    this.setState({
      sort: e.target.value,
      order: e.target.dataset.order
    })
  }

  buildSortMenu(){
    return <React.Fragment>
      <input type="radio" name="sort" value="name" data-order="ASC" id="sort_by_name_ASC" onChange={this.onSortChange} checked={this.state.sort === "name" && this.state.order === "ASC"} />
      <label htmlFor="sort_by_name_ASC">
        <FontAwesomeIcon icon={icon({name: 'arrow-up-a-z', style: 'solid'})} />
      </label>
      <input type="radio" name="sort" value="name" data-order="DESC" id="sort_by_name_DESC" onChange={this.onSortChange} checked={this.state.sort === "name" && this.state.order === "DESC"} />
      <label htmlFor="sort_by_name_DESC">
        <FontAwesomeIcon icon={icon({name: 'arrow-down-z-a', style: 'solid'})} />
      </label>
      
      <input type="radio" name="sort" value="count" data-order="ASC" id="sort_by_score_ASC" onChange={this.onSortChange} checked={this.state.sort === "count" && this.state.order === 'ASC'} />
      <label htmlFor="sort_by_score_ASC">
        <FontAwesomeIcon icon={icon({name: 'arrow-up-9-1', style: 'solid'})} />
      </label>
      <input type="radio" name="sort" value="count" data-order="DESC" id="sort_by_score_DESC" onChange={this.onSortChange} checked={this.state.sort === "count" && this.state.order === 'DESC'} />
      <label htmlFor="sort_by_score_DESC">
        <FontAwesomeIcon icon={icon({name: 'arrow-down-9-1', style: 'solid'})} />
      </label>
    </React.Fragment>
  }

  getEditedSkills(){
    const tabsKeys = getAllKeys(this.state.tabs)
    const stopsKeys = getAllKeys(this.state.stops)
    const deletedKeys = getAllKeys(this.state.deleted)
    const specialisationsKeys = getAllKeys(this.state.specialisations)
    const professionSynonymsKeys = getAllKeys(this.state.profession_synonyms)

    const allKeys = tabsKeys.concat(stopsKeys, deletedKeys, specialisationsKeys, professionSynonymsKeys)

    return allKeys
  }

  componentDidUpdate(prevProps, prevState) {
    // this.save()
  }

  async componentDidMount() {
    if (Object.keys(this.state.skills).length === 0){
      const skills = await getFirestoreSkills(this.state.profession)
      const stops = await getStops()
      // const modernisedSkillsObj = moderniseSkillsObj(skills)


      const skills_relations = this.state.parent_career === "" ? buildGlobalTree(skills) : buildProfessionTree(this.state.parent_career, skills)


      this.prepareSandbox(skills, stops, skills_relations);
    }
  }

  render() {
    const totalSkills = this.totalSkills()

    return (
      <main>
        <section className={`mainSection`}>
          <div className="leftBar">
            <div className="leftBar__head">
              <img src={logo} alt="Logo" className="leftBar__logo" />
              <span className="leftBar__version">v{process.env.REACT_APP_VERSION}</span>
            </div>
            
            <div className="leftBar__menu">
              <div className="leftBar__menu__block">
                <span>Sort by:</span><br />
                
                {this.buildSortMenu()}
              </div>
              <div className="leftBar__menu__block"><span>Skills left:</span><br /><b>{totalSkills}</b></div>
            </div>
            {
              this.state.editReady === true && 
              <AddSkill dataHandler={this.addSkillHandler} skills={this.state.skills} raw={this.state.raw} />
            }
            {
              this.state.editReady === true ?
              <LeftBar 
                raw={this.state.raw}
                skills={this.state.skills}
                stops={this.state.stops}
                deleted={this.state.deleted}
                specialisations={this.state.specialisations}
                profession_synonyms={this.state.profession_synonyms}
                editReady={this.state.editReady}
                stateHandler={this.stateHandler}
                updateRaw={this.setSynonymHandler}
                skillTypeClickHandler={this.handleTypeClick}
                sort={this.state.sort}
                order={this.state.order}
                containedPhrase={this.state.containedPhrase}
              />
              :
              <Loading />
            }
          </div>
          <div className={`workZone ${this.state.view}`}>
            {
              this.state.editReady === true && 
              <>
                <MainZone 
                  raw={this.state.raw}
                  tabs={this.state.tabs}
                  stateHandler={this.stateHandler}
                  skillDataHandler={this.skillDataHandler}
                  skillTypeClickHandler={this.handleTypeClick}
                />
                <TrashZone 
                  raw={this.state.raw}
                  stops={this.state.stops}
                  deleted={this.state.deleted}
                  stateHandler={this.stateHandler}
                  skillDataHandler={this.skillDataHandler}
                />
                <SpecialisationsZone 
                  profession={this.state.profession}
                  raw={this.state.raw}
                  specialisations={this.state.specialisations}
                  profession_synonyms={this.state.profession_synonyms}
                  stateHandler={this.stateHandler}
                  skillDataHandler={this.skillDataHandler}
                />
              </>
            }

            <ViewTabs 
              stateHandler={this.stateHandler}
              view={this.state.view}
            />
          </div>

          <Autosave 
            editReady={this.state.editReady} 
            profession={this.state.profession}
            parent_career={this.state.parent_career}
            name={this.state.name}
            deleted={this.state.deleted}
            stops={this.state.stops}
            raw={this.state.raw}
            tabs={this.state.tabs}
            specialisations={this.state.specialisations}
            profession_synonyms={this.state.profession_synonyms}
          />
          <SaveButton 
            profession={this.state.profession}
            parent_career={this.state.parent_career}
            name={this.state.name}
            deleted={this.state.deleted}
            stops={this.state.stops}
            raw={this.state.raw}
            tabs={this.state.tabs}
            specialisations={this.state.specialisations}
            profession_synonyms={this.state.profession_synonyms}
            level="1"
          />
          <MergeButton 
            level="0"
            raw={this.state.raw}
            tabs={this.state.tabs}
            stops={this.state.stops}
            deleted={this.state.deleted}
            specialisations={this.state.specialisations}
            profession_synonyms={this.state.profession_synonyms}
            skills={this.state.skills}
            profession={this.state.profession}
          />
          <Reload />
        </section>
      </main>
    );
  }
}