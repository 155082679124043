import React from 'react'
import {hasClass, removeClass} from '../../components/className.js'

export default class InnerInput extends React.Component {
  constructor(props) {
    super(props)

    this.handleStateSet = this.handleStateSet.bind(this)
    this.handleTabUpdate = this.handleTabUpdate.bind(this)

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onBlurHandler = this.onBlurHandler.bind(this)
    this.onFocusHandler = this.onFocusHandler.bind(this)
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this)
  }

  handleStateSet(data) {
    this.props.stateHandler(data)
  }

  handleTabUpdate(key, parentKey = "") {
    this.props.tabHandler(key, parentKey)
  }

  onChangeHandler(e) {
    
  }

  hideInput(e) {
    const parent = e.target.parentElement

    if(hasClass(parent, 'input')){
      removeClass(parent, 'input')
    }
  }

  onBlurHandler(e) {
    this.hideInput(e)
    this.updateSkillName(e)
  }

  onFocusHandler(e) {
    e.target.value = e.target.dataset.name
  }

  onKeyDownHandler(e) {
    if (e.key === 'Enter') {
      e.target.id !== "" ? this.updateSkillName(e) : this.createSkill(e)
    }
  }

  updateSkillName(e) {
    const id = e.target.id
    const value = e.target.value.toLowerCase().trim()
    const key = value.replaceAll(' ', '_')
    let raw = this.props.raw
    const prevItem = raw[id]
    
    if (id !== "" && id !== null) {
      if (
        raw[key] === undefined && 
        raw[id].name !== value && 
        value !== ''
      ) {
        delete raw[id]

        raw[key] = prevItem
        raw[key].name = value
        raw[key].updating = false


        this.handleStateSet({
          raw: raw
        })
      } else {
        this.handleStateSet({
          raw: {
          ...this.props.raw,
          [id]: {
            ...this.props.raw[id],
            updating: false,
          },
        }})
      }
    }
    

    e.target.value = ""
  }

  createSkill(e) {
    let elem = e.target
    const value = elem.value.toLowerCase().trim()
    const key = value.replaceAll(' ', '_')

    if (this.props.raw[key] === undefined) {
      let parentKey = elem.parentElement.dataset.parent

      if (parentKey === undefined){
        while(parentKey === undefined) {
          elem = elem.parentElement
          parentKey = elem.dataset.parent
        }
      }

      if (parentKey !== "") {
        this.handleTabUpdate(key, parentKey)
      } else {
        this.handleTabUpdate(key)
      }

      const type = ['soft', 'trait'].indexOf(parentKey) !== -1 ? parentKey : ""

      const obj = {
        raw: {
          ...this.props.raw,
          [key]: {
            type: type,
            name: value,
            visible: false,
            score: 0
          }
        },
      }
    
      this.handleStateSet(obj)
      this.hideInput(e)
      e.target.value = ""
    }
  }

  render() {
    return <React.Fragment>
      <input 
        autoFocus
        id={this.props.id}
        type="text" 
        data-name={this.props.name}
        className="innerInput"
        onChange={this.onChangeHandler}
        onBlur={this.onBlurHandler}
        onFocus={this.onFocusHandler}
        onKeyDown={this.onKeyDownHandler}
        placeholder={this.props.placeholder}
      />
    </React.Fragment>    
  }
}