import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { UserAuth } from '../../context/AuthContext';
import Editor from "../../classes/skills_editor/editor.js"
import { getSandbox } from "../../db/professionsSandbox.js"



const ProfessionsEditor = () => {
  const { profession } = useParams()
  const { user } = UserAuth();
  const [sandboxName, setSandboxName] = useState("")
  const [sandboxParent_career, setSandboxParent_career] = useState("")
  const [sandboxRaw, setSandboxRaw] = useState({})
  const [sandboxTabs, setSandboxTabs] = useState({})
  const [sandboxDeleted, setSandboxDeleted] = useState({})
  const [sandboxStops, setSandboxStops] = useState({})
  const [sandboxSpecialisations, setSandboxSpecialisations] = useState({})
  const [sandboxProfessionSynonyms, setProfessionSynonyms] = useState({})

  useEffect(() => {
    const fetchSandbox = async (profession) => {
      const data = await getSandbox(profession)

      setSandboxName(data.name)
      if (data.raw !== undefined) setSandboxRaw(data.raw)
      if (data.tabs !== undefined) setSandboxTabs(data.tabs)
      if (data.deleted !== undefined) setSandboxDeleted(data.deleted)
      if (data.stops !== undefined) setSandboxStops(data.stops)
      if (data.specialisations !== undefined) setSandboxSpecialisations(data.specialisations)
      if (data.profession_synonyms !== undefined) setProfessionSynonyms(data.profession_synonyms)
      if (data.parent_career !== undefined) setSandboxParent_career(data.parent_career)
    }

    fetchSandbox(profession)
  }, [profession])

  return (
    user.isAdmin === true && sandboxName !== "" && profession !== null &&
    <Editor
      profession={profession}
      sandbox={{
        name: sandboxName,
        parent_career: sandboxParent_career,
        raw: sandboxRaw,
        tabs: sandboxTabs,
        deleted: sandboxDeleted,
        stops: sandboxStops,
        specialisations: sandboxSpecialisations,
        profession_synonyms: sandboxProfessionSynonyms
      }}
    />
    
  )
}

export default ProfessionsEditor