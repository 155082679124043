import React, { useState, useEffect } from 'react'
import { dataType } from '../../components/dataType.js'
import String from './String'
import BigText from './BigText'
import Number from './Number'
import Boolean from './Boolean'
import List from './List'
import Expand from './Expand'

export default function Dictionary({ data, dataKey, dataHandler }) {
  const [obj, setObj] = useState({})

  const updateData = (key, value) => {
    let mutatedObj = Object.assign({}, obj)
    mutatedObj[key] = value
    if (value === '' || value === null) delete mutatedObj[key];
    
    dataHandler(dataKey, mutatedObj)
  }

  useEffect(() => {
    setObj(data)
  }, [data])

  return <div className="courseEdit__dictionary">{Object.keys(obj).map((key, i) => {
    const value = obj[key]

    return <React.Fragment key={i}>
      <div className="courseEdit__key">{key.replace('_', ' ')}:</div>
      <div className="courseEdit__value">
        {dataType(value, 'string') && key !== 'description' && <String data={value} dataKey={key} dataHandler={updateData} />}
        {dataType(value, 'string') && key === 'description' && <BigText data={value} dataKey={key} dataHandler={updateData} />}
        {dataType(value, 'number') && <Number data={value} dataKey={key} dataHandler={updateData} />}
        {dataType(value, 'boolean') && <Boolean data={value} dataKey={key} dataHandler={updateData} />}
        {dataType(value, 'array') && <Expand><List data={value} dataKey={key} dataHandler={updateData} /></Expand>}
        {dataType(value, 'object') && <Dictionary data={value} dataKey={key} dataHandler={updateData} />}
      </div>
    </React.Fragment>
  })}</div>
}