import React, { useState, useEffect } from 'react'
import ImageUpload from './ImageUpload'


export default function Image ({ src, onUpdate }) {
  const [url, setUrl] = useState("")
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  const uploadHandler = (url) => {
    onUpdate(url)
  }

  useEffect(() => {
    setError(false)
    setUrl(src)
  }, [src])

  return !error ? <div className="courseEdit__imgBlock">
    <p className="courseEdit__imgBlock__title">Image preview:</p>
    <img src={url} alt="" onError={onError} />
    <ImageUpload handler={uploadHandler} />
  </div> : <></>
};