import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import ProfessionsForm from "./Form"

export default function ProfessionsMain() {
  const { user } = UserAuth();
  const navigate = useNavigate()
  const onProfessionChange = (profession) => {
    navigate(profession !== '' ? profession : '/professions')
  }

  return user.isAdmin && <>
    <div className="mainZone__header">
      <ProfessionsForm handler={onProfessionChange} />
    </div>
    
    <Outlet />
  </>
}