import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/Styles.css';
import "./css/app.css"
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary' 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      
        <App />
      
    </ErrorBoundary>
  </BrowserRouter>
);
