import { db } from '../firebase'
import { 
  getDatabase, 
  ref, 
  get, 
  update, 
  // remove, 
  child, 
  // onValue
} from "firebase/database";
import { collection, getDocs, doc, query, writeBatch } from "firebase/firestore";
import {isArraysEquals} from '../components/objectFunctions.js'
import {copyObject} from '../components/copyObject.js'
import {getJobIdsData, getFamilyProfessions, professionChildrenCorrectTrash} from './professionsSandbox.js'

export async function getStops(){
  return await get(child(ref(getDatabase()), `stop_words`)).then((snapshot) => {
    if (snapshot.exists()) {
     return snapshot.val()
    } else {
      return {}
    }
  }).catch((error) => {
    return {}
  });
}

export async function getSkills(profession = null){
  const family_professions = await getFamilyProfessions(profession)
  const professions = family_professions

  return await get(child(ref(getDatabase()), `skills_v1`)).then((snapshot) => {
    if (snapshot.exists()) {
      const result = {}
      const data = snapshot.val()

      Object.keys(data).forEach(key => {
        const value = data[key]

        if (professions.length > 0 && value["professions"]) {
          value["professions"] = Object.fromEntries(
            Object.entries(value["professions"]).filter(([key, value]) => professions.includes(key))
          );
        }

        result[key] = value
      })

      return result
    } else {
      return {}
    }
  }).catch((error) => {
    return {}
  });
}

async function getSkillProfessions(key){
  const professionsQuerySnapshot = await getDocs(
    collection(db, "skills_v1", key, "professions")
  );

  const professionsData = {};

  professionsQuerySnapshot.forEach((professionDoc) => {
    const professionData = professionDoc.data();
    const professionId = professionDoc.id;
    professionsData[professionId] = professionData;
  });

  return professionsData
}

export async function getFirestoreSkills(profession = null){
  const family_professions = await getFamilyProfessions(profession)
  const professions = family_professions
  
  const result = {}
  const q = query(
    collection(db, "skills_v1")
  );

  const querySnapshot = await getDocs(q);
  
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const data = doc.data();
      const id = doc.id;

      if (data.name !== undefined) {
        // Retrieve the "professions" collection for the current skill
        const professionsData = await getSkillProfessions(id)

        // Filter professions to retrieve only family professions
        if (professions.length > 0 && professionsData) {
          Object.keys(professionsData).forEach(key => {
            if (!professions.includes(key)) {
              delete professionsData[key]
            }
          })
        }

        // Add the "professions" data to the current skill
        data.professions = professionsData;

        // Add the skill to the result
        result[id] = data;
      }
    })
  );

  return result
}

export function moderniseSkillsObj(data){
  const result = {}

  Object.keys(data).forEach(id => {
    const key = data[id]['skill_id']
    result[key] = data[id]
  })

  return result
}

async function exportSkillsToCloudDB(r_skills, sandbox_profession, jobIds){
  const profession = sandbox_profession.replace(/_v\d+/g, '')

  const isSkillInCloud = (data, key) => {
    return data[key] !== undefined
  }

  const batches = {}

  // const batch = writeBatch(db);
  // const second_batch = writeBatch(db);
  const skills = await getFirestoreSkills(profession)
  const cloud_skills = copyObject(skills)

  for (let key in r_skills) {
    const obj = copyObject(r_skills[key])
    const professions = copyObject(obj.professions)
    const cloud_skill_obj = typeof cloud_skills[key] === 'object' ? copyObject(cloud_skills[key]) : {}

    // Add job ids to profession
    if (Array.isArray(jobIds[key]) && professions[profession]) {
      professions[profession]["job_ids"] = jobIds[key]
    }
    
    if (!isSkillInCloud(cloud_skills, key)) {
      delete obj.professions

      if (batches[key] === undefined) batches[key] = [];
      
      batches[key].push({
        type: 'set',
        ref: doc(db, "skills_v1", key),
        data: obj
      })
    } else {
      // Delete profession data from obj to not duplicate professions data,
      // because "professions" stores only as subcollection
      delete obj.professions
      
      // Make object to compare new data with stored data
      // Delete "professions" from new data to make more clear comparison
      if (cloud_skill_obj['professions']) delete cloud_skill_obj['professions'];

      // Compare objects, if they're not equals update
      if (obj.synonyms !== undefined && Array.isArray(obj.synonyms)) {
        obj.synonyms = obj.synonyms.filter((k, i, arr) => arr.indexOf(k) === i)

        if (!Array.isArray(cloud_skill_obj.synonyms) || !isArraysEquals(obj.synonyms, cloud_skill_obj.synonyms)) {
          if (batches[key] === undefined) batches[key] = [];
          
          batches[key].push({
            type: 'update',
            ref: doc(db, "skills_v1", key),
            data: {"synonyms": obj.synonyms}
          })
        }
      }

      if (obj.professions_names !== undefined && Array.isArray(obj.professions_names)) {
        obj.professions_names = obj.professions_names.filter((k, i, arr) => arr.indexOf(k) === i)
        
        if (!Array.isArray(cloud_skill_obj.professions_names) || (Array.isArray(cloud_skill_obj.professions_names) && !isArraysEquals(obj.professions_names, cloud_skill_obj.professions_names))) {
          if (batches[key] === undefined) batches[key] = [];

          batches[key].push({
            type: 'update',
            ref: doc(db, "skills_v1", key),
            data: {"professions_names": obj.professions_names}
          })
        }
      }
    }

    // Add profession data if available
    if (typeof professions[profession] === 'object' && !Array.isArray(professions[profession]) && professions[profession] !== null) {
      if (batches[`${key}_${profession}`] === undefined) batches[`${key}_${profession}`] = [];
      const writeType = cloud_skills[key]?.professions[profession] ? "update" : "set"

      batches[`${key}_${profession}`].push({
        type: writeType,
        ref: doc(db, `skills_v1/${key}/professions/${profession}`),
        data: professions[profession]
      })
    }
  }

  // Set total amount of docs
  const docsTotal = Object.keys(batches).length

  // Set the amount of batches
  const batchesTotal = docsTotal > 20 ? Math.ceil(docsTotal/20) : 1
  const docsDone = []

  console.log(`Firestore docs to update - ${docsTotal}`)

  try {
    let currentBatchIndex = 0

    for (let i = 0; i < batchesTotal; i++) {
      const batch = writeBatch(db);
      let docsInBatch = 0

      Object.keys(batches).forEach(docName => {
        if (!docsDone.includes(docName) && docsInBatch <= 20) {
          // Save doc name in array to not repeat in future
          docsDone.push(docName)

          // Increment var to not proceed more than possible docs in batch
          docsInBatch++

          batches[docName].forEach(({ type, ref, data }) => {
            if (type === 'set') {
              batch.set(ref, data)
            } else if (type === 'update') {
              batch.update(ref, data)
            }
          })
        }
      })


      await batch.commit();
      console.log(currentBatchIndex)
      currentBatchIndex++;
    }

    return true;
  } catch (error) {
    console.error('Error occurred during batch writes:', error);
    return error;
  }
}

async function saveSkills(data, sandbox_profession, jobIds){
  const profession = sandbox_profession.replace(/_v\d+/g, '')
  const skills = await getSkills(profession)
  const dbRef = ref(getDatabase());
  const updates = {};
  const updatesJobIds = {};

  Object.keys(data).forEach(key => {
    const value = data[key]

    if (Array.isArray(jobIds[key]) && value.professions[profession]) {
      value.professions[profession].job_ids = jobIds[key]
    }

    // Skill not exist in Realtime DB Skills
    if (skills[key] === undefined) {
      updates[`skills_v1/${key}`] = value
    } else {
      // Skill exist

      // Add profession data
      // if (value.professions[profession] && skills[key].professions[profession] === undefined) {
      if (value.professions[profession]) {
        const job_ids = value.professions[profession]["job_ids"] || []

        delete value.professions[profession]["job_ids"]
        
        updates[`skills_v1/${key}/professions/${profession}`] = value.professions[profession]
        updatesJobIds[`skills_v1/${key}/professions/${profession}/job_ids`] = job_ids

        data[key]["professions"][profession] = value.professions[profession]
        data[key]["professions"][profession]["job_ids"] = job_ids
      }

      // Update professions names
      let professions_names = value.professions_names
      if (skills[key].professions_names !== undefined) {
        if (!skills[key].professions_names.includes(profession)) {
          if (!Array.isArray(professions_names)) {
            professions_names = [profession]
          } else {
            if (!professions_names.includes(profession)) professions_names.push(profession);
          }

          updates[`skills_v1/${key}/professions_names`] = professions_names

          data[key]["professions_names"] = professions_names
        }
      } else {
        updates[`skills_v1/${key}/professions_names`] = [profession]

        data[key]["professions_names"] = [profession]
      }
      
      

      // Update synonyms
      if (Array.isArray(value.synonyms) && value.synonyms.length > 0) {
        const synonymsToUpdate = value.synonyms.filter((k, i, arr) => arr.indexOf(k) === i)
        const synonymsStored = skills[key].synonyms

        if (
          !Array.isArray(synonymsStored) || 
          (Array.isArray(synonymsStored) && !isArraysEquals(synonymsToUpdate, synonymsStored))
        ) {
          updates[`skills_v1/${key}/synonyms`] = synonymsToUpdate

          data[key]["synonyms"] = synonymsToUpdate
        }
      }
    }
  })

  console.log(`RealtimeDB updates to make - ${Object.keys(updates).length}`)
  console.log(`RealtimeDB job_ids updates to make - ${Object.keys(updatesJobIds).length}`)
  
  update(dbRef, updates)
    .then(() => {
      update(dbRef, updatesJobIds)
        .then(() => {
          return data
        })
        .catch((err) => {
          console.log(err)
          return false
        })
      }
    )
    .catch((err) => {
      console.log(err)
      return false
    })
}

export function mergeSkills(data, profession, callback){
  const data_to_save = copyObject(data)

  getJobIdsData(profession)
    .then(job_ids => {
      console.log('Job Ids loaded')


      
      saveSkills(data_to_save, profession, job_ids)
        .then(() => {
          exportSkillsToCloudDB(data_to_save, profession, job_ids)
            .then(() => {
              professionChildrenCorrectTrash(profession)
                .then(() => {
                  callback(undefined, true)
                })
                .catch(error => {
                  callback(error)
                })
            })
            .catch(error => {
              callback(error)
            })
        })
        .catch((error) => {
          callback(error)
        });
    })
    .catch(err => {
      callback(err)
    })
  
}