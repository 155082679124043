import React, { useState, useEffect, useRef } from 'react'
import Image from './Image'
import Select from './Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { normaliseStr } from '../../components/normaliseStr.js'



export default function String({ data, dataKey, dataHandler }) {
  const [value, setValue] = useState("")
  const [edit, setEdit] = useState(false)
  const editClass = edit ? 'edit' : ''
  const inputEl = useRef(null)

  const isLink = () => value.startsWith('http://') || value.startsWith('https://')
  const changeView = () => { setEdit(!edit) }
  const blurHandler = (e) => {
    dataHandler(dataKey, e.target.value)
    changeView()
  }
  const changeHandler = (e) => { setValue(e.target.value) }
  const keyDownHandler = (e) => { if (e.key === 'Enter') inputEl.current.blur(); }
  const clickHandler = () => { if (edit === false) setEdit(true); }
  const onImageUpdate = (src) => {
    dataHandler(dataKey, src)
  }

  useEffect(() => {
    setValue(normaliseStr(data))
  }, [data])

  useEffect(() => {
    if (edit === true) {
      inputEl.current.focus();
      inputEl.current.value = value;
    }
  }, [edit, value])

  const buildStringBlock = () => {
    if (['type', 'language'].indexOf(dataKey) >= 0) {
      return <Select dataKey={dataKey} value={value} handler={dataHandler} />
    }

    return <div className={`courseEdit__string ${editClass}`} onClick={clickHandler}>
      <span>{value}</span>
      <input
        type="text"
        ref={inputEl}
        onChange={changeHandler}
        onBlur={blurHandler}
        onKeyDown={keyDownHandler}
        placeholder="Type data here..."
      />
    </div>
  }

  const buildLinkBlock = () => {
    return <div className={`courseEdit__string ${editClass}`}>
      <button onClick={changeView} className="courseEdit__editBtn">
        <FontAwesomeIcon icon={icon({ name: 'pencil', style: 'solid' })} />
      </button>
      <a href={value} target="_blank" rel="noreferrer">{value}</a>
      <input
        type="text"
        ref={inputEl}
        onChange={changeHandler}
        onBlur={blurHandler}
        onKeyDown={keyDownHandler}
        placeholder="Type data here..."
      />
      <Image src={value} onUpdate={onImageUpdate} />
    </div>
  }

  return <React.Fragment>
    {!isLink() && buildStringBlock()}
    {isLink() && buildLinkBlock()}
  </React.Fragment>
}