import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { getProfessions } from "../../db/professionsSandbox.js"

export default function Form({ handler }) {
  const { profession } = useParams()
  const [selected, setSelected] = useState("")
  const [professions, setProfessions] = useState([])

  useEffect(() => {
    setSelected(profession !== undefined ? profession : "")
  }, [profession])

  const handleChange = (event) => {
    if (event.target.name === 'profession') {
      handler(event.target.value)
    }
  }

  const sortProfessionsByStatus = (list, value) => {
    list = list.sort((a, b) => {
      if (a.status === value && b.status !== value) {
        return 1;
      }
      if (b.status === value && a.status !== value) {
        return -1;
      }
      return 0;
    })

    return list
  }

  useEffect(() => {
    getProfessions().then((list) => {
      list = sortProfessionsByStatus(list, 'merged')
      list = sortProfessionsByStatus(list, 'done')
      
      setProfessions(list)
    })
  }, [])
  
  return <div className="professionForm">
    <span>Working with:</span>
    <select name="profession" value={selected} onChange={handleChange}>
      <option value="">
        {professions.length === 0 ? 'Loading professions ...' : 'Select profession'}
      </option>
      {professions.map((item, i) => (
        
        <option value={item.key} key={i}> 
          {item.status !== "" && `[${item.status[0].toUpperCase()}] `}
          {item.parent_career !== "" && ' -- '}
          {item.key.replaceAll("_", " ")}
        </option>
      ))}
    </select>
  </div>
}