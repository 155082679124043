import React, {useState, useEffect, useCallback} from 'react'
import { dataType } from '../../components/dataType.js'
import String from './String'
import BigText from './BigText'
import Number from './Number'
import Boolean from './Boolean'
import List from './List'
import Dictionary from './Dictionary'
import AlertIcon from './AlertIcon'


const createName = (id, data) => {
  if (data.link !== undefined) {
    const arr = data.link.split('/')
    const course = arr[arr.length - 1] === '' ? arr[arr.length - 2] : arr[arr.length - 1]
    
    return course.replace(/([^0-9a-zA-Z])/g, '_')
  } else if (data.title !== undefined && data.title !== '') {
    return data.title.toLowerCase().replace(/([^0-9a-zA-Z])/g, '_')
  } else {
    return id
  }
}

const correctName = (id, data) => {
  const name = createName(id, data)
  const course_type = (data.course_type || '_').replace(' ', '_')
  const platform = (data.platform || '_').replace(' ', '_')
  data.name = `${platform}_${course_type}_${name}`

  return data
}

const correctPlatform = (data) => {
  if (data.platform === undefined || data.platform === '' || data.platform === null) {
    let arr = data.link.split('.')
    data.platform = arr[0].includes('http') || arr[0].includes('www') ? arr[1] : arr[0]
  }

  return data
}

const correctCourseType = (data) => {
  if (data.course_type === undefined || data.course_type === null || data.course_type === '') {
    data.course_type = 'course'
  }

  return data
}

const correctData = (id, data) => {
  data = correctPlatform(data)
  data = correctName(id, data)
  data = correctCourseType(data)
  
  return data
}


export default function CourseZone({data, saveHandler, deleteHandler}){
  const [courseData, setCourseData] = useState({})
  const [courseId, setCourseId] = useState("")
  let alerts = 0

  const incrementAlerts = () => {
    alerts++
  }

  const saveCourse = () => {
    saveHandler(courseId, courseData)
  }

  const deleteCourse = () => {
    deleteHandler(courseId)
  }
  
  const prepareCourseData = useCallback((data) => {
    let obj = 'data' in data ? data['data'] : {}
    let id = 'id' in data ? data['id'] : 0
    obj = correctData(id, obj)

    setCourseData(obj)
    setCourseId(id)
  }, [])

  useEffect(() => {
    if (typeof data === 'object' && data !== null) {
      prepareCourseData(data)
    }
  }, [prepareCourseData, data])

  const updateCourseData = (key, value) => {
    setCourseData({
      ...courseData,
      [key]: value
    })
  }

  const createArrayOfData = (data) => {
    const arr = [
      {key: 'title', value: data.title},
      {key: 'description', value: data.description}
    ]

    for (let key in data) {
      if (['title', 'description'].indexOf(key) === -1){
        arr.push({key: key, value: data[key]})
      }
    }

    return arr
  }

  const buildContent = (data) => {
    const dataList = createArrayOfData(data)

    return dataList.map((elem, i) => {
      const key = elem.key
      const value = elem.value

      if (value === '' || value === null) {
        incrementAlerts()
      }

      return <React.Fragment key={i}>
        <div className="courseEdit__key">
          {(value === "" || value === null) && <AlertIcon />}
          {key.replace('_', ' ')}:
        </div>
        <div className="courseEdit__value">
          {dataType(value, 'string') && key !== 'description' && <String data={value} dataKey={key} dataHandler={updateCourseData} />}
          {dataType(value, 'string') && key === 'description' && <BigText data={value} dataKey={key} dataHandler={updateCourseData} />}
          {dataType(value, 'number') && <Number data={value} dataKey={key} dataHandler={updateCourseData} />}
          {dataType(value, 'boolean') && <Boolean data={value} dataKey={key} dataHandler={updateCourseData} />}
          {dataType(value, 'array') && <List data={value} dataKey={key} dataHandler={updateCourseData} />}
          {dataType(value, 'object') && <Dictionary data={value} dataKey={key} dataHandler={updateCourseData} />}
        </div>
      </React.Fragment>
    })
  }

  const buildActionBtns = () => {
    return <div className="courseBtns">
      <button className="courseBtns__green" onClick={saveCourse}>{alerts > 0 && <AlertIcon />}save</button>
      <button className="courseBtns__red" onClick={deleteCourse}>delete</button>
    </div>
  }

  return <React.Fragment>
    {typeof courseData === 'object' && courseData !== undefined ? buildContent(courseData) : <p>Choose course to edit</p>}
    {typeof courseData === 'object' && courseData !== undefined && buildActionBtns()}
  </React.Fragment>
}