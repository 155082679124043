import {copyObject} from './copyObject.js'

export const findPath = (ob, key) => {
  const path = [];

  const keyExists = (obj) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    } else if (obj.hasOwnProperty(key)) {
      return true;
    } else if (Array.isArray(obj)) {
      let parentKey = path.length ? path.pop() : "";

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`);
        const result = keyExists(obj[i], key);
        if (result) {
          return result;
        }
        path.pop();
      }
    } else {
      for (const k in obj) {
        path.push(k);
        const result = keyExists(obj[k], key);
        if (result) {
          return result;
        }
        path.pop();
      }
    }
    return false;
  };

  keyExists(ob);

  return path;
}

export const updateObject = (obj, path, key, value = "", remove = "") => {
  let parent = obj;

  for (let i = 0; i < path.length - 1; i += 1) {
    parent = parent[path[i]];
  }

  parent[path[path.length - 1]] = {
    ...parent[path[path.length - 1]],
    [key]: value
  }

  return obj;
}

export const deleteKey = (obj, path) => {
  const copiedObj = copyObject(obj)

  path.reduce((acc, key, index) => {
    if (index === path.length - 1) {
      delete acc[key];
      return true;
    }
    return acc[key];
  }, copiedObj);

  return copiedObj;
}

export const getAllKeys = (obj, result = []) => {
  if (Array.isArray(obj)) {
    for (var i = 0; i < obj.length; i++) {
      getAllKeys(obj[i], result);
    }
  } else {
    for (var key in obj) {
      result.push(key);
      
      if (typeof obj[key] == 'object' || Array.isArray(obj[key])) {
        getAllKeys(obj[key], result);
      }
    }
  }
  return result;
}

export const setLastKeyEmpty = (data, path) => {
  path.pop()

  let obj = copyObject(data)

  path.forEach((k, i) => {
    if (i === path.length - 1){
      obj[k] = ""
    } else {
      obj = obj[k]
    }
  })

  return obj
}

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];

    if (isObject(val1) && isObject(val2) && !deepEqual(val1, val2)) {
      return false;
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!isArraysEquals(val1, val2)) {
        return false
      }
    } else if (val1 !== val2) {
      return false
    }
  }

  return true;
}

export const isObject = (object) => {
  return object !== null && typeof object === 'object' && !Array.isArray(object);
}

export const isArraysEquals = (arr1, arr2) => {
  if (arr1.length === arr2.length) {
    for (const item of arr1) {
      if (!arr2.includes(item)) {
        return false
      }
    }

    return true
  } else {
    return false
  }
}