import React from 'react'
import { deepEqual } from '../../components/objectFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default class AddSkill extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      opened: false,
      skills: {},
      skill: ''
    }

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.filterSkills = this.filterSkills.bind(this)
  }

  open(){
    this.setState({opened: true})
  }

  close(){
    this.setState({opened: false})
  }

  handleChange (event) {
    const value = event.target.value
    
    if (value !== '') {
      this.setState({skill: value})
    }
  }

  handleSubmit() {
    if (this.state.skill !== '') {
      this.addSkill()
      this.close()
    }
  }

  prepareSkillData(){
    const skill = this.props.skills[this.state.skill]

    return skill
  }

  addSkill(){
    const skillKey = this.state.skill
    const skillData = this.prepareSkillData()
    const raw = JSON.parse(JSON.stringify(this.props.raw))
    raw[skillKey] = skillData

    this.props.dataHandler({[skillKey]: skillData}, raw)
  }

  filterSkills(){
    const skills = {}

    Object.keys(this.props.skills).forEach(key => {
      if (this.props.raw[key] === undefined) {
        skills[key] = this.props.skills[key]
      } else if (this.props.raw[key] !== undefined && this.props.raw[key].name !== this.props.skills[key].name) {
        skills[key] = this.props.skills[key]
      }
    })
    this.setState({
      skills: skills
    })
  }

  componentDidMount(){
    this.filterSkills()
  }

  componentDidUpdate(prevProps, prevState){
    if (!deepEqual(prevProps.raw, this.props.raw)) {
      this.filterSkills()
    }
  }

  render() {
    const openedClass = this.state.opened ? 'opened' : ''
    const btnDisabled = this.state.skill === ''

    return <div className={`leftBar__addSkill ${openedClass}`}>
      <button className="leftBar__addSkill_btn" onClick={this.open}>add skill</button>
      <div className="leftBar__addSkill__form">
        <select name="skill" onChange={this.handleChange}>
          <option value="">Select skill</option>
          {Object.keys(this.state.skills).map((key, i) => (
            <option value={key} key={i}>{this.props.skills[key].name} ({this.props.skills[key].type})</option>
          ))}
        </select>
        <button onClick={this.handleSubmit} disabled={btnDisabled}><FontAwesomeIcon icon={icon({name: 'check', style: 'solid'})} /></button>
      </div>
    </div>
  }
}