import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import Signin from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import LoginRoute from './components/LoginRoute';
import Home from './components/Home';
import Layout from './components/Layout'
import ProfessionsMain from './components/professions/Main';
import ProfessionDashboard from './components/professions/Dashboard';
import ProfessionsEditor from './components/professions/Editor';
import Courses from './components/Courses';


function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route 
          path='/' 
          element={
            <LoginRoute>
              <Signin />
            </LoginRoute>
          } 
        />
        <Route element={<ProtectedRoute />}>
          <Route path='home' element={<Layout><Home /></Layout>} />
          <Route path='professions' element={<Layout path="professions"><ProfessionsMain /></Layout>} >
            <Route path=':profession' element={<ProfessionDashboard />} />
          </Route>
          <Route path='professions/:profession/edit' element={<ProfessionsEditor />} />
          <Route path='courses' element={<Courses />} />
        </Route>
      </Routes>
    </AuthContextProvider>
  )
}

export default App