import { firebaseApp } from '../firebase'
import { getFirestore, collection, getDocs } from "firebase/firestore"

export const isAdmin = async (email) => {
  const admins = []
  const docsSnap = await getDocs(collection(getFirestore(firebaseApp), "admins"));
  docsSnap.forEach(doc => {
    admins.push(doc.data().email)
  })

  return admins.indexOf(email) >= 0
}