import React, { useState, useEffect, useRef } from 'react'
import DOMPurify from 'dompurify';
import { Editor } from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

function HTMLdata({data}){
  return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data)}}></div>
}

export default function BigText({ data, dataKey, dataHandler }) {
  const [value, setValue] = useState("")
  const [edit, setEdit] = useState(false)
  const editClass = edit ? 'edit' : ''
  const editorRef = useRef(null);
  
  const changeView = () => { setEdit(!edit) }
  const clickHandler = () => { if (edit === false) setEdit(true); }
  const applyChanges = () => {
    dataHandler(dataKey, editorRef.current.getContent())
    changeView()
  }

  useEffect(() => {
    setValue(data)
  }, [data])

  return <div className={`courseEdit__bigText ${editClass}`} onClick={clickHandler}>
    {edit && 
      <button className="courseEdit__saveBtn" onClick={applyChanges}>
        <FontAwesomeIcon icon={icon({ name: 'floppy-disk', style: 'solid' })} /><span>save</span>
      </button>
    }
    {!edit && <HTMLdata data={value} />}
    {edit &&
      <Editor
        apiKey='f1saj17n5fu7cshdnzebiy3ikyttueab1sevf28fkf6wffzl'
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'lists link charmap ',
            'paste wordcount'
          ],
          toolbar: 'undo redo | ' +
            'bold italic | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat',
          content_style: ''
        }}
      />
    }
  </div>
}