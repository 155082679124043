export const getElemByClass = (e, className) => {
  let elem = e

  if (!hasClass(elem, className)) {
    while(!hasClass(elem, className)) {
      elem = elem.parentElement
    }
  } 

  return elem
}

export const hasClass = (elem, value) => {
    return typeof elem.className === 'string' ? elem.className.split(' ').indexOf(value) > -1 : false
  }

export const addClass = (elem, value) => {
  const className = elem.className
  
  if (typeof className === 'string') {
    const classArr = className.split(' ')

    if (classArr.indexOf(value) === -1) {
      elem.className = `${className} ${value}`
    }
  }
}

export const removeClass = (elem, value) => {
  const className = elem.className

  if (typeof className === 'string') {
    const classArr = className.split(' ')
    const index = classArr.indexOf(value)

    if (index > -1) {
      classArr.splice(index, 1)
      elem.className = classArr.join(' ')
    }
  }
}