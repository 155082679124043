import React, { useState, useEffect } from 'react';
import { getCoursesPathList } from "../db/coursesRealtimeDB.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const CoursesForm = ({ handleSubmit }) => {
  const [path, setPath] = useState("")
  const [pathId, setPathId] = useState(null)
  const [pathList, setPathList] = useState([])

  const getPathId = (path) => {
    for (let elem of pathList) {
      if (elem.key === path) {
        return elem.id
      }
    }

    return null
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const id = getPathId(value)

    if (name === 'path') {
      setPath(value)
      setPathId(id)
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    if (path !== '') {
      handleSubmit(path, pathId)
    }
  }

  useEffect(() => {
    getCoursesPathList().then((list) => {
      setPathList(list)
    })
  }, [])

  return (<React.Fragment>
    <p>Choose Courses library</p>
    <form onSubmit={submit}>
      <select name="path" onChange={handleChange}>
        <option value="*">Select library</option>
        {pathList.map((item, i) => (
          <option data-id={item.id} value={item.key} key={i}>{item.name}</option>
        ))}
      </select>
      <button className="submitBtn" type="submit">
        <FontAwesomeIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
        Start editing
      </button>
    </form>
  </React.Fragment>)
}

export default CoursesForm