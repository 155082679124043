import { dbCourses } from '../firebase'
import { collection, addDoc } from "firebase/firestore";

export async function uploadCourseToFirestore(data){
  return addDoc(collection(dbCourses, "courses"), data)
    .then(() => {
      return true
    })
    .catch((error) => {
      console.log(error)
      return false
    })
}