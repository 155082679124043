import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import LogoutBtn from './LogoutBtn'
import logo from "../img/logo.svg"
import { getFirestore, collection, getDocs } from "firebase/firestore"
import { firebaseApp } from '../firebase'
import CoursesForm from './CoursesForm'
import Editor from "../classes/courses_editor/editor.js"


const Courses = () => {
  const { user } = UserAuth();
  const [admin, setAdmin] = useState(null)
  const [path, setPath] = useState(null)
  const [pathId, setPathId] = useState(null)

  const getMessage = (admin) => {
    if (admin === null) {
      return <p>Loading...</p>
    } else if (admin === false) {
      return <p>You have no rights here</p>
    } else {
      return ''
    }
  }

  const isAdmin = async (uid) => {
    const admins = []
    const docsSnap = await getDocs(collection(getFirestore(firebaseApp), "admins"));
    docsSnap.forEach(doc => {
      admins.push(doc.data().uid)
    })

    setAdmin(admins.indexOf(uid) >= 0)
  }

  const handleSubmit = (path, pathId, data) => {
    if (path !== '') {
      setPath(path)
      setPathId(pathId)
    }
  }


  useEffect(() => {
    isAdmin(user.uid)
  }, [user.uid])

  return (<main>
    {
      admin === true && path !== null && path !== '' ?
        <Editor path={path} pathId={pathId} />
        :
        <section className="mainSection">
          <div className="leftBar">
            <div className="leftBar__head">
              <img src={logo} alt="Logo" className="leftBar__logo" />
              <span className="leftBar__version">v{process.env.REACT_APP_VERSION}</span>
            </div>
            <div className="leftBar__login">
              <h3>Hello, {user.displayName}</h3>
              {getMessage(admin)}
              <CoursesForm handleSubmit={handleSubmit} />
              <LogoutBtn />
            </div>
          </div>
        </section>
    }
  </main>)
}

export default Courses