import React from 'react';
import {getElemByClass, hasClass, addClass, removeClass} from '../../components/className.js'

export default class Synonyms extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleStateSet = this.handleStateSet.bind(this)
  }

  handleClick(e){
    if (e.detail === 1 && e.target.className !== 'removeBtn') {
      const elem = getElemByClass(e.target, 'synonymsBlock')

      if(!hasClass(elem, 'opened')){
        addClass(elem, 'opened')
      } else {
        removeClass(elem, 'opened')
      }
    }
  }

  handleRemove(e){
    const key = e.target.dataset.key
    const parent = this.props.parent
    let synonymsArr = this.props.raw[parent].synonyms
    let index = synonymsArr.indexOf(key)
    if (index >= 0) synonymsArr.splice(index, 1)
    if (synonymsArr.length === 0) {
      const elem = getElemByClass(e.target, 'synonymsBlock')
      removeClass(elem, 'opened')
    }

    this.handleStateSet({
      raw: {
        ...this.props.raw,
        [key]: {
          ...this.props.raw[key],
          visible: true
        },
        [parent]: {
          ...this.props.raw[parent],
          synonyms: synonymsArr
        },
      }
    })
  }

  handleStateSet(data){
    this.props.handler(data)
  }

  render(){
    if (Array.isArray(this.props.keys)) {
      let keys = this.props.keys
      let first_key = keys[0]

      return <div className="synonymsBlock" onClick={this.handleClick}>
        <span>{this.props.raw[first_key]?.name || first_key} and etc...</span>

        {
          this.props.keys.filter(key => this.props.raw[key] !== undefined).length > 0 ?
          <div className="synonymsEdit">
            {
              this.props.keys.map(key => {
                if (this.props.raw[key] !== undefined) {
                  return <React.Fragment key={key}>
                    <button className="removeBtn" data-key={key} onClick={this.handleRemove}>x</button>
                    <span>{this.props.raw[key].name}</span>
                  </React.Fragment>
                } else {
                  return <React.Fragment key={key}></React.Fragment>
                }
              })
            }
          </div> : <></>
        }
      </div>
    } 
  }
}