const filterByProfession = (profession, db_skills) => {
  const skills = {}
  const profession_underscore = profession.replaceAll(" ", "_")


  Object.keys(db_skills).forEach((key) => {
    const name = db_skills[key].name
    let parent = ""
    let synonyms = []
    let professions = db_skills[key].professions
    
    Object.keys(professions).forEach(prof_key => {
      const elem = professions[prof_key]
      
      if (elem.name === profession || elem.name === profession_underscore) {
        parent = elem.parent
        if (Array.isArray(elem.synonyms) && elem.synonyms.length > 0) synonyms = elem.synonyms
      }
    })


    skills[key] = {
      name: name,
      parent: parent,
      synonyms: synonyms
    }
  })

  return skills
}

// function buildTree(obj, parent) {
//   let tree = {};
//   Object.keys(obj).forEach(key => {
//     if (obj[key].parent === parent) {
//       tree[key] = buildTree(obj, key);
//     }
//   });
//   return tree;
// }

// const filterTree = (tree) => {
//   return Object.fromEntries(
//     Object.entries(tree).filter(([_, value]) => Object.keys(value).length > 0)
//   );
// }

// const fillTree = (tree_filtered, skills) => {
//   Object.keys(tree_filtered).forEach(key => {
//     if (tree_filtered[key] !== null && tree_filtered[key] !== undefined) {
//       fillTree(tree_filtered[key], skills);

//       tree_filtered[key].__name = skills[key].name;
//       tree_filtered[key].__synonyms = skills[key].synonyms;
//     }
//   });
// }

export const buildProfessionTree = (profession, db_skills) => {
  const skills = filterByProfession(profession, db_skills)

  // const tree = buildTree(skills, "")
  // const tree_filtered = filterTree(tree)

  // fillTree(tree_filtered, skills);
  
  return skills
}