import React from "react"

export default class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.request = this.request.bind(this)
    this.onClose = this.onClose.bind(this)
  }
  
  handleClick(e){
    const option = e.target.dataset.option
    this.request(option)
  }
  
  request(option) {
    this.props.handler(option)
  }

  onClose = e => this.request('cancel')

  render(){
    if (!this.props.show) {
      return null
    }
    const optionsText = {
      removeTab: 'Remove tab',
      removeSkill: 'Remove skill',
      removeSkillAndChildren: 'Remove skill and children',
      mergeData: 'Merge data',
      isReload: 'Reload',
      cancel: 'Cancel',
    }
    const modalButtons = this.props.options.map((option, n) => {
      return <button data-option={option} onClick={this.handleClick} key={n}>
        {optionsText[option]}
      </button>
    })

    return <div className="modal">
      <button className="modal__close" onClick={this.onClose}>x</button>
      <div className="modal__text">{this.props.text}</div>
      <div className="modal__buttons">
        {modalButtons}
        <button data-option="cancel" className="cancel" onClick={this.handleClick}>cancel</button>
      </div>
    </div>
  }
}