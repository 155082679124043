export function convertMinutesToStr(n, unitOfTime = 'month'){
  const iterable = {
    minute: 60,
    hour: 24,
    day: 30,
    month: 12
  }

  let name = ""
  let value = n

  for (let key in iterable) {
    name = key
    
    if (value / iterable[key] < 1 || unitOfTime === key) break

    value = value / iterable[key]
  }

  value = Math.round(value)

  return `${value} ${name}${value > 1 ? 's' : ''}`
}